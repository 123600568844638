<template>
  <div class="flex flex-col relative overflow-x-auto sm:rounded-lg container shadow-lg h-[calc(100vh-100px)] w-full mx-auto top-[100px]">
      <LoadingMolecule v-if="!loaded && !linears"/>
      <div v-else class="p-6 w-full">
          <div class="flex flex-row w-full justify-center">
              <ul class="inline-flex items-center -space-x-px">
                  <li class="border border-gray-400 rounded-l-md p-1 text-gray-400 hover:bg-gray-300 hover:text-white hover:border-gray-300" @click="previousPage()">
                      <component :is="heroIcons['ChevronLeftIcon']" fill="none" class="stroke-1 h-7 w-7" />
                  </li>
                  <li class="border border-gray-400 p-1 text-body-2 text-gray-400 hover:bg-gray-300 hover:text-white hover:border-gray-300">
                      <div class="h-7 min-w-[50px] px-1 flex items-center justify-center"> Allée : {{ linears[0] ? linears[0].aisle : null }} - Rang : {{ linears[0] ? linears[0].position : null }}</div>
                  </li>
                  <li class="border border-gray-400 rounded-r-md p-1 text-gray-400 hover:bg-gray-300 hover:text-white hover:border-gray-300" @click="nextPage()">
                      <component :is="heroIcons['ChevronRightIcon']" fill="none" class="stroke-1 h-7 w-7" />
                  </li>
              </ul>
          </div>
          <div class="flex flex-col overflow-y-hidden overflow-x-auto pr-5">
            <div 
            v-for="linear in linears" :key="linear.id" 
            class="flex flex-row items-center justify-between min-w-[150px]">
                <div class="flex flex-col min-w-[150px] w-12%"
                v-for="section in linear.modules" 
                :key="section.id"
                :class="'w-['+(section.moduleOrder.length*12)+'%]'"
                @click="linear.selected[section.id] = !linear.selected[section.id]"
                >
                    <div class="w-full h-full flex flex-col p-2 mb-2 border-b-4 border-b-gray-800 relative min-h-[115px]" :class="linear.selected[section.id] ? 'bg-primary-100' : null">
                        <div v-for="product in section.products" :key="product.id" class="w-full h-full flex flex-col justify-end items-end">
                            <img :src="product.imageUrl" alt="" class="w-50%">
                        </div>
                        <div class="absolute -translate-y-1/4 bottom-0 left-2 flex flex-col items-center justify-center">
                            <div class="rounded-[100px] shadow-xl w-[4rem] h-[1.5rem] text-white flex flex-col items-center justify-center mb-2" :class="section.productCount && section.productCount > 0 ? 'bg-primary-800' : 'bg-danger-800'">
                                 {{ section.productCount ? section.productCount : 0 }}
                            </div>
                            <div class="rounded-[100px] shadow-xl w-[4rem] h-[1.5rem] text-black flex flex-col items-center justify-center bg-gray-300">
                                  {{ section.weight ? section.weight : 0 }}
                            </div>
                        </div>
                    </div>
                    <div class="pt-5">
                        <div v-if="details" class="w-full flex flex-col bg-gray-50 min-h-[115px] justify-start">
                          <!--<VueQrcode :value="section.id" :color="{ dark: '#000000ff', light: '#ffffffff' }" :type="'image/png'"/>-->
                            <div v-for="product in section.products" :key="product.id" class="flex flex-col">
                                <span class="font-bold text-2xl"> {{ product.brand }}</span>
                                <span class="text-l">{{ product.reference }}</span>
                                <span class="text-sm">{{ product.barCode }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col justify-center items-center w-15% h-full">
                        <router-link :to="'/shelf/'+linear.serialNumber">
                            <div class="flex flex-row items-center rounded-md border border-primary-500 bg-primary-500 hover:bg-primary-400 hover:border-primary-400 shadow-xl hover:shadow-lg p-2 text-white ml-2">
                                <component :is="heroIcons['ChevronRightIcon']" 
                                fill="white" 
                                class="stroke-2 h-[1.5rem]"
                                />
                                Réglages
                            </div>
                        </router-link>
                    </div>
                </div>
          </div>
      </div>
  </div>
</template>

  
  
  <script>
  import Linear from '@/utils/entities/Linear';
  import LoadingVue from '../components/elements/Loading.vue';
  import LinearModules from '../components/groups/LinearModules.vue';
  import WebsocketService from '@/utils/WebsocketService';
  import * as heroIcons from '@heroicons/vue/24/outline';
  
  export default {
    name: 'ShelfViewerView',
    components: {
      LoadingVue,
      LinearModules,
    },
    data() {
      return {
        heroIcons,
        loaded: false,
        linears: [],
        subscribedLinears: new Set(),
        alreadySubscribed: false,
      };
    },
    computed: {
      currentAisle() {
        return this.linears[0] ? this.linears[0].aisle : null;
      },
      currentPosition() {
        return this.linears[0] ? this.linears[0].position : null;
      },
      isMobile() {
        return this.$device.isMobile;
      },
      isPortrait() {
        return this.$device.isPortrait;
      },
    },
    async mounted() {

        WebsocketService.initializeWebSocket(process.env.VUE_APP_WEBSOCKET_PATH);
        
        await this.getLinears().then(async () => {
            await this.waitForWebSocketConnection().then(() => {
                this.checkAndSubscribe();
            });    
        });

        this.loaded = true;
    },
    beforeUnmount() {
      this.linears.forEach(linear => {
        linear.unsubscribeWebSocket()
      })
      this.subscribedLinears=new Set();
    },
    watch:{
      linears:{
        handler(newLinears, oldLinears) {
            if(this.alreadySubscribed){
                oldLinears.forEach((linear) => {
                    if (!newLinears.includes(linear)) {
                        linear.unsubscribeWebSocket();
                    this.subscribedLinears.delete(linear); // Remove from the Set
                    }
                });

                newLinears.forEach((linear) => {
                    if (!this.subscribedLinears.has(linear)) {
                        linear.initializeWebSocketListener();
                        this.subscribedLinears.add(linear); // Add to the Set to track subscription
                    }
                });
            }
        },
        deep: true,
      }
    },
    methods: {
      async waitForWebSocketConnection() {
            return new Promise((resolve) => {
            const interval = setInterval(() => {
                if (WebsocketService.isConnected) {
                clearInterval(interval);
                resolve();
                }
            }, 1000); // Check every 1 second
            });
        },
        checkAndSubscribe() {
            this.linears.forEach((linear) => {
                if (!this.subscribedLinears.has(linear)) {
                    linear.initializeWebSocketListener();
                    this.subscribedLinears.add(linear); // Add to the Set to track subscription
                }
            });

            this.alreadySubscribed = true
        },
      async getLinears() {
        try {
          const response = await this.$axios.get(`${process.env.VUE_APP_API_BASE_PATH}/linears${window.location.search}`, {
            withCredentials: true,
          });
          if (response.status === 200) {
            this.linears = response.data['hydra:member']
              .sort((a, b) => b.storey - a.storey)
              .map(linear => {
                linear.modules.sort((a, b) => a.moduleOrder - b.moduleOrder);
                return new Linear(linear);
              });
          }
        } catch (error) {
          console.error('Error fetching linears:', error);
        }
      },

      nextPage() {
        this.$router.replace("/shelf_viewer?aisle=4&position=2");
        this.$router.go();
      },
      previousPage() {
        this.$router.replace("/shelf_viewer?aisle=4&position=1");
        this.$router.go();
      },
    },
  };
  </script>
  