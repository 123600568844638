import axios from 'axios'
import Module from "./Module"
import WebsocketService from '../WebsocketService'

export default class Linear { 


    constructor(object) {
        this.selected = {}
        this.build(object)
    }

    initializeWebSocketListener() {

        WebsocketService.initializeWebSocket(process.env.VUE_APP_WEBSOCKET_PATH);

        WebsocketService.sendMessage({
            command: 'subscribe',
            resource: `/api/linears/${this.serialNumber}`,
        });

        WebsocketService.addListener(async (type, data) => {
            if (type === 'message' && data.resource === `/api/linears/${this.serialNumber}` && data.command == "update") {
                await this.retrieveEntity();
            }
        });
    }
    
    unsubscribeWebSocket() {
        WebsocketService.sendMessage({
            command: 'unsubscribe',
            resource: `/api/linears/${this.serialNumber}`,
        });
    }

    build(object){
        object.id ? this.id = object.id : this.id = null
        object.ip ? this.ip = object.ip : this.ip = null
        object.version ? this.version = object.version : this.version = null
        this.connected = false
        object.serialNumber ? this.serialNumber = object.serialNumber : this.serialNumber = null
        if(object.modules){
            var modules = []
            object.modules.forEach((module) => {
                modules.push(new Module(module))
                this.selected[module.id] = this.selected[module.id] ? true : false
            })
            modules.sort((a,b) => {
                return a.moduleOrder - b.moduleOrder
            })
            this.modules = modules
        }
        object.aisle ? this.aisle = object.aisle : this.aisle = null
        object.position ? this.position = object.position : this.position = null
        object.storey ? this.storey = object.storey : this.storey = null
        object.mode ? this.mode = object.mode : this.mode = null
        object.lastConnectedAt ? this.lastConnectedAt = object.lastConnectedAt : this.lastConnectedAt = null
    }

    async retrieveEntity() {
        if(this.serialNumber){
            await axios
            .get(process.env.VUE_APP_API_BASE_PATH+'/linears/'+this.serialNumber,
            {
                withCredentials: true
            })
            .then(response => {
                if(response.status == 200){
                    this.build(response.data)
                }
            })
        }
    }

    getModulesForDisplay(){
        var modules = []
        this.modules.forEach(object => {
            var foundModule = -1
            if(object.product){
                foundModule = modules.findIndex(element => element.product && element.product.barCode === object.product.barCode)
            }
            if(foundModule < 0){
                var pushModule = object
                pushModule.moduleOrder = [object.moduleOrder]
                modules.push(pushModule)
            } else {
                modules[foundModule].moduleOrder.push(object.moduleOrder)
                modules[foundModule].productCount += object.productCount
            }
        })
        this.modules = modules
    }

    countSelected(){
        var valren = 0;
        Object.keys(this.selected).forEach(element => {
            this.selected[element] ? valren++ : null
        })
        return valren
    }

    toggleAll(){
        var state = false
        Object.keys(this.selected).forEach(element => {
            this.selected[element] ? state = true : null
        })

        Object.keys(this.selected).forEach(element => {
            state ? this.selected[element] = false  : this.selected[element] = true
        })
    }

    async changeLocation(){
        await axios
        .patch(process.env.VUE_APP_API_BASE_PATH+'/linears/'+this.serialNumber+'/location', 
            {
                "aisle": this.aisle,
                "position": this.position,
                "storey": this.storey
            }
            ,
            {
                withCredentials: true,
                headers: { 'Content-Type' : 'application/merge-patch+json' } 
            })
        .then(response => {
            this.build(response.data)
        })
    }

    async toggleMode(){
        await axios
        .patch(process.env.VUE_APP_API_BASE_PATH+'/linears/'+this.serialNumber+'/mode', 
            {
                "mode": this.mode == "default" ? "settings" : "default"
            }
            ,
            {
                withCredentials: true,
                headers: { 'Content-Type' : 'application/merge-patch+json' } 
            })
        .then(response => {
            response.data.mode ? this.mode = response.data.mode : this.mode = null
        })
    }

    async emptySelected(){
        var values = {
            modules: []
        }
        var products = []
        Object.keys(this.selected).forEach(element => {
            if(this.selected[element]){
                values.modules.push({
                    id : element, 
                })
                var module = this.modules.find((el) => {
                    return el.id === element
                })
                module.products.forEach((element) => {
                    products.indexOf(element) === -1 ? products.push(element) : null;
                })
            }
        })
        products.forEach(async function(element) {
            await axios
            .patch(process.env.VUE_APP_API_BASE_PATH+'/products/'+element.barCode+'/dissociate', 
            values,
            {
                withCredentials: true,
                headers: { 'Content-Type' : 'application/merge-patch+json' } 
            })

        })
        this.toggleAll()
        this.retrieveEntity()
    }

    update(){
        this.$socket.send({
            resource: '/api/linears/'+this.serialNumber,
            command: 'update_esp',
        })
    }

    calibrateProductsSelected(){
        var values = {}
        Object.keys(this.selected).forEach(element => {
            var moduleNumber = Object.keys(this.linear.module).find(key => this.linear.module.id === key)
            this.selected[element] ? values['module'+this.linear.module[moduleNumber].moduleOrder] = true : null
        })
        this.$socket.send({
            resource: '/api/linears/'+this.serialNumber,
            command: 'calibrate_product',
            value: values
        })
        this.toggleAll()
    }

    findModuleIndex(id){
        return this.modules.findIndex(element => {
            return element.id === id
        })
    }

    isSelectedAndConnected(){
        this.countSelected() && this.connected
    }


}