<template>
    <div class="relative sm:rounded-lg overflow-hidden container shadow-lg h-full w-full mx-auto" :class="isMobile ? 'top-[71px]' : 'top-[100px]'">
        <LoadingVue v-if="!loaded" class="relative" :class="isMobile ? 'top-[71px]' : 'top-[100px]'"/>
        <div v-else-if="isMobile" class="w-full h-full flex flex-col pl-5 ">
            <div class="flex flex-row justify-center md:justify-between flex-wrap py-5">
                <span class="font-bold text-l lg:text-2xl ml-2 "> {{ linear.serialNumber }}</span>
                <span>Allée : {{ linear.aisle }} Rang : {{ linear.position }} Étage : {{ linear.storey }}</span>
            </div>
            <div class="flex flex-row overflow-y-hidden overflow-x-auto pr-5">
                <div class="flex flex-col min-w-[150px] w-12%"
                v-for="section in linear.modules" 
                :key="section.id"
                :class="'w-['+(section.moduleOrder.length*12)+'%]'"
                @click="linear.selected[section.id] = !linear.selected[section.id]"
                >
                    <div class="w-full h-full flex flex-col p-2 mb-2 border-b-4 border-b-gray-800 relative min-h-[115px]" :class="linear.selected[section.id] ? 'bg-primary-100' : null">
                        <div v-for="product in section.products" :key="product.id" class="w-full h-full flex flex-col justify-end items-end">
                            <img :src="product.imageUrl" alt="" class="w-50%">
                        </div>
                        <div class="absolute -translate-y-1/4 bottom-0 left-2 flex flex-col items-center justify-center">
                            <div class="rounded-[100px] shadow-xl w-[4rem] h-[1.5rem] text-white flex flex-col items-center justify-center mb-2" :class="section.productCount && section.productCount > 0 ? 'bg-primary-800' : 'bg-danger-800'">
                                 {{ section.productCount ? section.productCount : 0 }}
                            </div>
                            <div class="rounded-[100px] shadow-xl w-[4rem] h-[1.5rem] text-black flex flex-col items-center justify-center bg-gray-300">
                                  {{ section.weight ? section.weight : 0 }}
                            </div>
                        </div>
                    </div>
                    <div class="pt-5">
                        <div v-if="details" class="w-full flex flex-col bg-gray-50 min-h-[115px] justify-start">
                          <!--<VueQrcode :value="section.id" :color="{ dark: '#000000ff', light: '#ffffffff' }" :type="'image/png'"/>-->
                            <div v-for="product in section.products" :key="product.id" class="flex flex-col">
                                <span class="font-bold text-2xl"> {{ product.brand }}</span>
                                <span class="text-l">{{ product.reference }}</span>
                                <span class="text-sm">{{ product.barCode }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      <ModalVue
      v-show="associateLocation"
      :title="'Associer un Emplacement au Linéaire'"
      :confirmButtonLabel="'CONFIRMER'"
      @closeModal="associateLocation = false"
      @confirm="changeLocation()"
      >
          <template v-slot:body>
              <div class="flex flex-col flex-wrap justify-around items-center p-4">
                  <InputVue class="w-25% min-w-[200px] mb-5"
                  v-model="linear.aisle" 
                  :height="'large'" 
                  :label="'Allée'" 
                  :placeholder="'Numéro de l\'allée'" 
                  :state="stateAisle" 
                  :message="helperAisle"/>
                  <InputVue class="w-25% min-w-[200px] mb-5"
                  v-model="linear.position" 
                  :height="'large'"
                  :label="'Rang'" 
                  :placeholder="'Numéro de rang'" 
                  :state="statePosition" 
                  :message="helperPosition"/>
                  <InputVue class="w-25% min-w-[200px]"
                  v-model="linear.storey" 
                  :height="'large'" 
                  :label="'Étage'" 
                  :placeholder="'Numéro d\'étage'" 
                  :state="stateStorey" 
                  :message="helperStorey"/>
              </div>
          </template>
      </ModalVue>
      <ModalVue
      v-show="calibrateModules"
      :title="'Calibrer les Modules sélectionnés'"
      :confirmButtonLabel="'CALIBRER ('+linear.countSelected()+')'"
      @closeModal="calibrateModules = false"
      @confirm="calibrateModulesSelected(calibratingWeight)"
      >
          <template v-slot:body>
              <div class="flex flex-col justify-center items-center p-4">
                  <span>
                      Avant la calibration, vous pouver effectuer une tare...
                  </span>
                  <span>
                      Pour se faire, assurez-vous qu'il n'y ait plus aucun produit sur les modules sélectionnés
                  </span>
                  <span class="flex flex-row w-85% items-center justify-center my-2">
                      Puis cliquez sur 
                  </span>
                  <ButtonVue class="w-[200px] mt-2"
                      :filling="'filled'"
                      :label="'TARE ('+ linear.countSelected()+')'"
                      :height="'medium'"
                      :state="linear.countSelected() ? 'default' : 'disabled'"
                      @click="tareModulesSelected()"
                      />
                  <span class="text-xl font-bold mt-2">
                      Sinon
                  </span>
                  <span>
                      Placer un poids sur chaque module sélectionné puis préciser le Poids
                  </span>
                  <InputVue class="w-[200px] mt-2"
                  v-model="calibratingWeight" 
                  :height="'medium'" 
                  :label="'Poids en gramme'" 
                  :placeholder="'Poids en gramme'" 
                  :state="stateCalibratingWeight" 
                  :message="helperCalibratingWeight"/>
              </div>
          </template>
      </ModalVue>
      <ModalVue
      v-show="tareModules"
      :title="'Tare des Modules sélectionnés'"
      :confirmButtonLabel="'TARE ('+linear.countSelected()+')'"
      @closeModal="tareModules = false"
      @confirm="tareModulesSelected()"
      >
          <template v-slot:body>
              <span>
                  Assurez-vous qu'il n'y ait plus aucun produit sur les modules sélectionnés avant de lancer la tare
              </span>
          </template>
      </ModalVue>
      <ModalVue
      v-show="associateProducts"
      :title="'Associer un Produit aux Modules sélectionnés'"
      :confirmButtonLabel="'AJOUTER ('+linear.countSelected()+')'"
      @closeModal="associateProducts = false"
      @confirm="associateProductSelected()"
      >
          <template v-slot:body>
              <div class="flex flex-col flex-wrap justify-around items-center p-4 w-full">
                  <div class="p-2 mt-6 flex flex-row justify-between w-full"
                  :class="productMessage ? 'items-center' : 'items-end'">
                      <InputVue class="w-full"
                      :label="'Entrer ou Scanner un Code Barre'"
                      :placeholder="'Code Barre'"
                      v-model="barCode"
                      :height="'large'"
                      :state="productState"
                      :message="productMessage"
                      />
                      <ButtonVue class="ml-2"
                      :filling="'filled'"
                      :label="null"
                      :height="'large'"
                      :icon="'MagnifyingGlassIcon'"
                      :state="'neutral'"
                      @click="getProduct(barCode)"
                      />
                  </div>
                  <AutocompleteVue class="p-2 w-full"
                  v-model="brandAndRef" 
                  :height="'large'" 
                  :label="'Rechercher un produit'" 
                  :placeholder="'Marque Référence'"
                  :objects="products" 
                  @update:modelValue="getProducts()"
                  @setValue="e => setProduct(e)"/>
                  <ButtonVue class="w-[200px] mt-2"
                  :filling="'filled'"
                  :label="'VIDER L\'ÉTAGERE'"
                  :height="'medium'"
                  :state="linear.countSelected() ? 'danger' : 'disabled'"
                  @click="linear.emptySelected()"
                  />
              </div>
          </template>
      </ModalVue>
      </div>
      <div v-else class="w-full h-full flex flex-col p-10">
          <div class="flex flex-row justify-center md:justify-between flex-wrap">
              <div class="flex flex-col min-w-[300px] lg:mb-0 mb-2">
                  <span class="font-bold text-l lg:text-2xl ml-2 "> {{ linear.serialNumber }}</span>
                  <div class="flex items-center justify-start my-2"
                  :class="isMobile ? 'flex-col-reverse' : 'flex-row'">
                      <div class="flex flex-row items-center justify-start my-2">
                          <ButtonVue
                          :filling="'filled'"
                          :label="'MODE : '+linear.mode.toUpperCase()"
                          :height="'medium'"
                          :state="'default'"
                          @click="linear.toggleMode()"
                          />
                          <div v-if="linear.mode == 'settings'" class="w-[15px] h-[15px] rounded-[15px] mx-2" :class="linear.connected ? 'bg-success-600':'bg-danger-600'"></div>
                          <span v-if="linear.mode == 'settings'">{{linear.connected ? 'connecté' : 'en attente de connexion'}}</span>
                      </div>
                  </div>
                  <div class="flex flex-col mb-2">
                      <span>Version {{ linear.version }}</span>
                      <span>Dernière connexion</span>
                      <span>{{ new Date(linear.lastConnectedAt).toLocaleDateString() }} - {{ new Date(linear.lastConnectedAt).toLocaleTimeString() }}</span>
                  </div>
                  <div v-if="linear.aisle && linear.position && linear.storey" class="flex flex-col">
                      <span>Allée : {{ linear.aisle }}</span>
                      <span>Rang : {{ linear.position }}</span>
                      <span>Étage : {{ linear.storey }}</span>
                  </div>
              </div>
              <div class="flex flex-col lg:w-34% xl:w-25%">
                  <div class="flex flex-row w-full">
                      <ButtonVue class="w-50% mr-1"
                      :filling="'filled'"
                      :label="'EMPLACEMENT'"
                      :height="'medium'"
                      :state="'default'"
                      @click="associateLocation = true"
                      />
                      <ButtonVue class="w-50% ml-1"
                      :filling="'filled'"
                      :label="linear.countSelected() ? 'UNSELECT ALL' : 'SELECT ALL'"
                      :height="'medium'"
                      :state="'default'"
                      @click="linear.toggleAll()"
                      />
                  </div>
                  <ButtonVue class="w-full mt-2"
                  :filling="'filled'"
                  :label="'ASSOCIER PRODUIT ('+linear.countSelected()+')'"
                  :height="'medium'"
                  :state="linear.countSelected() ? 'default' : 'disabled'"
                  @click="linear.countSelected() ? associateProducts = true : null"
                  />
                  <div v-if="linear.mode == 'settings'" class="flex flex-row w-full mt-2">
                      <ButtonVue class="w-50% mr-1"
                      :filling="'filled'"
                      :label="'TARE ('+ linear.countSelected()+')'"
                      :height="'medium'"
                      :state="linear.countSelected() && linear.connected ? 'default' : 'disabled'"
                      @click="linear.countSelected() ? tareModules = true : null"
                      />
                      <ButtonVue class="w-50% ml-1"
                      :filling="'filled'"
                      :label="'CALIBRER ('+ linear.countSelected()+')'"
                      :height="'medium'"
                      :state="linear.countSelected() && linear.connected ? 'default' : 'disabled'"
                      @click="linear.countSelected() ? calibrateModules = true : null"
                      />
                  </div>
                  <ButtonVue class="w-full mt-2"
                  :filling="'filled'"
                  :label="'SUPPRIMER'"
                  :height="'medium'"
                  :state="'danger'"
                  @click="deleteLinear()"
                  />
              </div>
          </div>
          <div class="flex flex-row overflow-y-hidden overflow-x-auto pr-5 w-full justify-between h-full">
                <div class="flex flex-col min-w-[150px] w-full"
                v-for="section in linear.modules" 
                :key="section.id"
                :class="'w-['+(section.moduleOrder.length*12)+'%]'"
                @click="linear.selected[section.id] = !linear.selected[section.id]"
                >
                    <div class="w-full h-full flex flex-col p-2 mb-2 border-b-4 border-b-gray-800 relative min-h-[115px]" :class="linear.selected[section.id] ? 'bg-primary-100' : null">
                        <div v-if="togglePictures">
                            <div v-for="product in section.products" :key="product.id" class="w-full h-full flex flex-col justify-end items-end">
                                <img :src="product.imageUrl" alt="" class="w-50%">
                            </div>
                        </div>
                        <div class="absolute -translate-y-1/4 bottom-0 left-2 flex flex-col items-center justify-center">
                            <div class="rounded-[100px] shadow-xl w-[4rem] h-[1.5rem] text-white flex flex-col items-center justify-center mb-2" :class="section.productCount && section.productCount > 0 ? 'bg-primary-800' : 'bg-danger-800'">
                                {{ section.productCount ? section.productCount : 0 }}
                            </div>
                            <div v-if="toggleWeights" class="rounded-[100px] shadow-xl w-[4rem] h-[1.5rem] text-black flex flex-col items-center justify-center bg-gray-300">
                                {{ section.weight ? section.weight : 0 }}
                            </div>
                        </div>
                    </div>
                    <div class="pt-5">
                        <div v-if="details && toggleReferences" class="w-full flex flex-col bg-gray-50 min-h-[115px] justify-start">
                            <!--<VueQrcode :value="section.id" :color="{ dark: '#000000ff', light: '#ffffffff' }" :type="'image/png'"/>-->
                            <div v-for="product in section.products" :key="product.id" class="flex flex-col">
                                <span class="font-bold text-2xl"> {{ product.brand }}</span>
                                <span class="text-l">{{ product.reference }}</span>
                                <span class="text-sm">{{ product.barCode }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col m-2 h-full items-center justify-between min-h-[150px] pt-6">
                    <div @click="togglePictures = !togglePictures">
                        <component
                        :is="togglePictures ? heroIcons['ChevronUpIcon'] : heroIcons['ChevronDownIcon']"
                        class="pointer-events-none stroke-1 stroke-neutral-600 w-6 h-6"/>
                    </div>
                    <div @click="toggleWeights = !toggleWeights">
                        <component
                        :is="toggleWeights ? heroIcons['ChevronUpIcon'] : heroIcons['ChevronDownIcon']"
                        class="pointer-events-none stroke-1 stroke-neutral-600 w-6 h-6"/>
                    </div>
                    <div @click="toggleReferences = !toggleReferences">
                        <component
                        :is="toggleReferences ? heroIcons['ChevronUpIcon'] : heroIcons['ChevronDownIcon']"
                        class="pointer-events-none stroke-1 stroke-neutral-600 w-6 h-6"/>
                    </div>
                </div>
          </div>
      <ModalVue
      v-show="associateLocation"
      :title="'Associer un Emplacement au Linéaire'"
      :confirmButtonLabel="'CONFIRMER'"
      @closeModal="associateLocation = false"
      @confirm="changeLocation()"
      >
          <template v-slot:body>
              <div class="flex flex-col flex-wrap justify-around items-center p-4">
                  <InputVue class="w-25% min-w-[200px] mb-5"
                  v-model="linear.aisle" 
                  :height="'large'" 
                  :label="'Allée'" 
                  :placeholder="'Numéro de l\'allée'" 
                  :state="stateAisle" 
                  :message="helperAisle"/>
                  <InputVue class="w-25% min-w-[200px] mb-5"
                  v-model="linear.position" 
                  :height="'large'"
                  :label="'Rang'" 
                  :placeholder="'Numéro de rang'" 
                  :state="statePosition" 
                  :message="helperPosition"/>
                  <InputVue class="w-25% min-w-[200px]"
                  v-model="linear.storey" 
                  :height="'large'" 
                  :label="'Étage'" 
                  :placeholder="'Numéro d\'étage'" 
                  :state="stateStorey" 
                  :message="helperStorey"/>
              </div>
          </template>
      </ModalVue>
      <ModalVue
      v-show="calibrateModules"
      :title="'Calibrer les Modules sélectionnés'"
      :confirmButtonLabel="'CALIBRER ('+linear.countSelected()+')'"
      @closeModal="calibrateModules = false"
      @confirm="calibrateModulesSelected(calibratingWeight)"
      >
          <template v-slot:body>
              <div class="flex flex-col justify-center items-center p-4">
                  <span>
                      Avant la calibration, vous pouver effectuer une tare...
                  </span>
                  <span>
                      Pour se faire, assurez-vous qu'il n'y ait plus aucun produit sur les modules sélectionnés
                  </span>
                  <span class="flex flex-row w-85% items-center justify-center my-2">
                      Puis cliquez sur 
                  </span>
                  <ButtonVue class="w-[200px] mt-2"
                      :filling="'filled'"
                      :label="'TARE ('+ linear.countSelected()+')'"
                      :height="'medium'"
                      :state="linear.countSelected() ? 'default' : 'disabled'"
                      @click="tareModulesSelected()"
                      />
                  <span class="text-xl font-bold mt-2">
                      Sinon
                  </span>
                  <span>
                      Placer un poids sur chaque module sélectionné puis préciser le Poids
                  </span>
                  <InputVue class="w-[200px] mt-2"
                  v-model="calibratingWeight" 
                  :height="'medium'" 
                  :label="'Poids en gramme'" 
                  :placeholder="'Poids en gramme'" 
                  :state="stateCalibratingWeight" 
                  :message="helperCalibratingWeight"/>
              </div>
          </template>
      </ModalVue>
      <ModalVue
      v-show="tareModules"
      :title="'Tare des Modules sélectionnés'"
      :confirmButtonLabel="'TARE ('+linear.countSelected()+')'"
      @closeModal="tareModules = false"
      @confirm="tareModulesSelected()"
      >
          <template v-slot:body>
              <span>
                  Assurez-vous qu'il n'y ait plus aucun produit sur les modules sélectionnés avant de lancer la tare
              </span>
          </template>
      </ModalVue>
      <ModalVue
      v-show="associateProducts"
      :title="'Associer un Produit aux Modules sélectionnés'"
      :confirmButtonLabel="'AJOUTER ('+linear.countSelected()+')'"
      @closeModal="associateProducts = false"
      @confirm="associateProductSelected()"
      >
          <template v-slot:body>
              <div class="flex flex-col flex-wrap justify-around items-center p-4 w-full">
                  <div class="p-2 mt-6 flex flex-row justify-between w-full"
                  :class="productMessage ? 'items-center' : 'items-end'">
                      <InputVue class="w-full"
                      :label="'Entrer ou Scanner un Code Barre'"
                      :placeholder="'Code Barre'"
                      v-model="barCode"
                      :height="'large'"
                      :state="productState"
                      :message="productMessage"
                      />
                      <ButtonVue class="ml-2"
                      :filling="'filled'"
                      :label="null"
                      :height="'large'"
                      :icon="'MagnifyingGlassIcon'"
                      :state="'neutral'"
                      @click="getProduct(barCode)"
                      />
                  </div>
                  <AutocompleteVue class="p-2 w-full"
                  v-model="brandAndRef" 
                  :height="'large'" 
                  :label="'Rechercher un produit'" 
                  :placeholder="'Marque Référence'"
                  :objects="products" 
                  @update:modelValue="getProducts()"
                  @setValue="e => setProduct(e)"/>
                  <ButtonVue class="w-[200px] mt-2"
                  :filling="'filled'"
                  :label="'VIDER L\'ÉTAGERE'"
                  :height="'medium'"
                  :state="linear.countSelected() ? 'danger' : 'disabled'"
                  @click="linear.emptySelected()"
                  />
              </div>
          </template>
      </ModalVue>
      </div>
  </div>
</template>

<script>
import axios from 'axios'
import Linear from '@/utils/entities/Linear'
import Product from '@/utils/entities/Product'
import LoadingVue from '../components/elements/Loading.vue'
//import VueQrcode from 'vue-qrcode'
import InputVue from '../components/elements/Input.vue'
import ButtonVue from '../components/elements/Button.vue'
import ModalVue from '../components/containers/Modal.vue'
import AutocompleteVue from '../components/elements/Autocomplete.vue'
import WebsocketService from '@/utils/WebsocketService'

import * as heroIcons from '@heroicons/vue/24/outline'

export default ({
  name: 'ShelfView',
  components: {
      LoadingVue,
      //VueQrcode,
      InputVue,
      ButtonVue,
      ModalVue,
      AutocompleteVue
  },
  props:{
      details:{
          type: Boolean,
          default: true
      },
      inputProducts:{
          type: Object,
          default: null
      },
  },
  data() {
      return {
            heroIcons: heroIcons,
            loaded: false,
            linear: {},
            state: undefined,

            associateLocation: false,
            tareModules: false,
            calibrateModules: false,
            associateProducts: false,

            calibratingWeight: '500',
            stateCalibratingWeight: "default",
            helperCalibratingWeight: "",

            stateAisle: "default",
            statePosition: "default",
            stateStorey: "default",
            helperAisle: "",
            helperPosition: "",
            helperStorey: "",

            barCode: null,
            product : null,
            productState: 'default',
            productMessage: null,

            brandAndRef: "",
            products : [],

            updateClicked: false,
            toggleWeights: false,
            togglePictures: true,
            toggleReferences: true,

      }
  },    
  computed: {
      isMobile() {
        return this.$device.isMobile;
        },
        isPortrait() {
        return this.$device.isPortrait;
        },
    },
  async mounted(){
        WebsocketService.initializeWebSocket(process.env.VUE_APP_WEBSOCKET_PATH);
        this.linear = new Linear({ serialNumber: this.$route.params.id})
        await this.linear.retrieveEntity().then(async () => {
            await this.waitForWebSocketConnection().then(() => {
                this.linear.initializeWebSocketListener();
                WebsocketService.sendMessage({
                    command: 'ping',
                    resource: `/api/linears/${this.linear.serialNumber}`,
                });
            });
        })

        WebsocketService.addListener((type, data) => {
            if (type === 'message') {
                this.handleWebSocketMessage(data);
            }
        });

        this.loaded = true
  },
  unmounted(){
    this.linear.unsubscribeWebSocket()
  },
  methods:{
        async waitForWebSocketConnection() {
            return new Promise((resolve) => {
            const interval = setInterval(() => {
                if (WebsocketService.isConnected) {
                clearInterval(interval);
                resolve();
                }
            }, 1000); // Check every 1 second
            });
        },
      handleWebSocketMessage(data){
          if(data.resource === "/api/linears/"+this.linear.serialNumber){
                switch (data.command){
                    case "acknowledgment": 
                        this.linear.connected = true
                        if(data.value === 'update'){
                            alert('La demande de mise à jour a bien été prise en compte')
                            window.location.reload()
                        }
                        break;
                    case "tare_response":
                        data.value.forEach((element1) => {
                            var index = this.linear.modules.findIndex(element2 => {
                                return element2.id === element1.module
                            })
                            this.linear.modules[index].weight = element1.weight
                        });
                        break;
                    case "calibrate_module_response": 
                        data.value.forEach((element1) => {
                            var index = this.linear.modules.findIndex(element2 => {
                                return element2.id === element1.module
                            })
                            this.linear.modules[index].weight = element1.weight
                        });
                        break;
                    case "delete":
                        this.$router.replace('/shelves')
                        break;
                    case "calibrate_product_response": 
                        break;
                    default:
                        break;
              }
          } 
      },
      productsForSelect(){
          return this.products.map(element => {
              return { 
                  value: element.barCode, 
                  name: element.brand + ' ' + element.reference
              }
          })
      },
      async getProduct(barcode){
          await axios
          .get(process.env.VUE_APP_API_BASE_PATH+'/products/'+ barcode,
          {
              withCredentials: true
          })
          .then(response => {
              if(response.status == 200){
                  this.product = new Product(response.data)
                  this.productMessage = this.product.brand+', '+this.product.reference
                  this.productState = "success"
              }
          })
          .catch(() =>{
              this.product = null
              this.productMessage = "Code Barre non reconnu"
              this.productState = "danger"
          });
      
      },

      async getProducts(){
          await axios
          .get(process.env.VUE_APP_API_BASE_PATH+'/products?simplesearch='+this.brandAndRef,
          {
              withCredentials: true
          })
          .then(response => {
              this.products = response.data['hydra:member']
              this.products.forEach((element,index) => {
                  this.products[index].description = element.brand+', '+element.reference
              })

          })
      },

      setProduct(event){
          this.brandAndRef = event.description
          this.product = new Product(event)
          this.products = []
      },


      async deleteLinear(){
          if (confirm("Êtes-vous sûr de vouloir supprimer le linéaire ? Cette action est irréversible")) {
              await axios
              .delete(process.env.VUE_APP_API_BASE_PATH+'/linears/'+this.$route.params.id,
              {
                  withCredentials:true,
              })
              .then(response => {
                  if(response.status == 204){
                      this.$router.replace('/shelves')
                  }
              })
          }
      },
      ping(){
          this.updateClicked = false
      },
      calibrateModulesSelected(weight){
          var values = {}
          Object.keys(this.linear.selected).forEach(element => {
              var moduleNumber = this.linear.modules.find(key => element === key.id).moduleOrder
              this.linear.selected[element] ? values['module'+moduleNumber] = parseInt(weight) : null
          })
          console.log(values)
          WebsocketService.sendMessage({
              resource: '/api/linears/'+this.linear.serialNumber,
              command: 'calibrate_module',
              value: values
          });
          this.calibrateModules = false
          this.linear.toggleAll()
      },
      tareModulesSelected(){
          var values = {}
          Object.keys(this.linear.selected).forEach(element => {
              var moduleNumber = this.linear.modules.find(key => element === key.id).moduleOrder
              this.linear.selected[element] ? values['module'+moduleNumber] = true : null
          })
          WebsocketService.sendMessage({
                resource: '/api/linears/'+this.linear.serialNumber,
                command: 'tare',
                value: values
          });
          this.tareModules = false
          this.linear.toggleAll()
      },
      update(){
          this.updateClicked = true
          this.linear.update()
      },
      async associateProductSelected(){
          if(this.product){
              let json = {
                   modules: []
              }
              Object.keys(this.linear.selected).forEach(element => {
                  if(this.linear.selected[element]){
                      json.modules.push({
                          id : element
                      })
                  }
              })
              await this.product.associateProduct(json).then(async () => {
                await this.linear.retrieveEntity()
              })
              this.associateProducts = false
          }
      },
      changeLocation(){
          var temoin = [false,false,false]
          if(!this.linear.aisle){
              temoin[0] = true
              this.stateAisle = "danger" 
              this.helperAisle = "Doit être définit"
          } else {
              this.stateAisle = "default" 
              this.helperAisle = ""
          }
          if(!this.linear.position){
              temoin[1] = true
              this.statePosition = "danger" 
              this.helperPosition = "Doit être définit"
          } else {
              this.statePosition = "default" 
              this.helperPosition = ""
          }
          if(!this.linear.storey){
              temoin[2] = true
              this.stateStorey = "danger" 
              this.helperStorey = "Doit être définit"
          } else {
              this.stateStorey = "default" 
              this.helperStorey = ""
          }
          for (var i = 0; i < temoin.length; i++){
              if (temoin[i]){
                  return
              }
          }
          this.linear.changeLocation()
          this.associateLocation = false
      },
  },
})

</script>