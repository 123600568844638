<template>
    <div class="scanner-container">
      <div v-show="!isLoading">
        <video poster="data:image/gif,AAAA" ref="scanner"></video>
      </div>
    </div>
  </template>
  
  <script>
import { BrowserMultiFormatReader, BarcodeFormat, Exception, DecodeHintType } from '@zxing/library';

    const hints = new Map();
    const formats = [BarcodeFormat.EAN_13];
    hints.set(DecodeHintType.POSSIBLE_FORMATS,formats);


  export default {
    name: "BarcodeReaderVue",
  
    data() {
      return {
        isLoading: true,
        codeReader: null,
        isMediaStreamAPISupported: navigator && navigator.mediaDevices && "enumerateDevices" in navigator.mediaDevices,
      };
    },
  
    mounted() {
      if (!this.isMediaStreamAPISupported) {
        throw new Exception("Media Stream API is not supported");
      }


        const hints = new Map();
        const formats = [
            BarcodeFormat.EAN_13,
            BarcodeFormat.CODABAR,
            BarcodeFormat.CODE_39,
            BarcodeFormat.CODE_93,
            BarcodeFormat.CODE_128,
            BarcodeFormat.EAN_8,
            BarcodeFormat.ITF,
            BarcodeFormat.UPC_A,
            BarcodeFormat.UPC_E,
            BarcodeFormat.UPC_EAN_EXTENSION,
        ];
        hints.set(DecodeHintType.POSSIBLE_FORMATS,formats);
        this.codeReader = new BrowserMultiFormatReader(hints);
        this.start();
        this.$refs.scanner.oncanplay = () => {
            this.isLoading = false;
            this.$emit("loaded");
        };
    },
  
    beforeUnmount() {
      this.codeReader.reset();
    },
  
    methods: {
      start() {
        /*this.codeReader.decodeFromConstraints(
        {
            audio: false,
            video: {
            facingMode: 'environment',
            },
        },
        this.$refs.scanner,
        (result) => {
            if (result) {
                this.$emit("decode", result.text);
                this.$emit("result", result);
            }
        }
        );*/

        this.codeReader.decodeFromVideoDevice(undefined, this.$refs.scanner, (result) => {
          if (result) {
            this.$emit("decode", result.text);
            this.$emit("result", result);
          }
        });
      },
    },
  };
  </script>
  
  <style scoped>
  video {
    max-width: 100%;
    max-height: 100%;
  }
  .scanner-container {
    position: relative;
  }
  
  </style>
  