<template>
    <div class="flex flex-col items-start w-full">
        <div class="w-full overflow-y-scroll" style="max-height: calc(100vh - 310px);">
            <table class="table-auto w-full">
                <tbody>
                    <tr v-for="(option, index) in dataValue" :key="index" class="border-t py-2 !h-text-input-medium">
                        <td class="px-1 !h-text-input-medium w-full text-center">{{ option.title }}</td>
                        <td class="px-1 !h-text-input-medium text-center py-2">
                            <span>
                                <CheckboxVue v-model="option.state" :height="'large'" @input="updateCheckbox(index)" />
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import CheckboxVue from "@/components/elements/Checkbox.vue";

export default {
    name: "ColumnTableVue",
    components: {
        CheckboxVue,
    },
    props: {
        modelValue: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            dataValue: [],
        };
    },
    methods: {
        updateCheckbox(index) {
            this.dataValue[index].state = !this.dataValue[index].state;
            this.emitChanges();
        },
        emitChanges() {
            this.$emit("update:modelValue", this.dataValue);
        },
    },
    watch: {
        modelValue: {
            handler(newVal) {

                this.dataValue = newVal.map(option => ({ ...option }));
            },
            immediate: true,
            deep: true,
        },
    }
};
</script>
