import axios from 'axios'
import Product from "./Product"
import Linear from "./Linear"

export default class Module { 

    constructor(object) {
        object['@id'] ? this.atId  = object['@id']  : this.atId  = null
        object.id ? this.id = object.id : this.id = null
        if(object.products){
            var products = []
            object.products.forEach((product) => {
                products.push(new Product(product))
            })

            this.products = products
        }
        object.productCount ? this.productCount = object.productCount : this.productCount = 0
        object.parentLinear ? this.parentLinear = new Linear(object.parentLinear) : this.parentLinear = null
        object.moduleOrder ? this.moduleOrder = object.moduleOrder : this.moduleOrder = 0
        object.weight ? this.weight = object.weight : this.weight = 0
        this.selected = false
    }

    async retrieveEntity() {
        if(this.id){
            await axios
            .get(process.env.VUE_APP_API_BASE_PATH+'/modules/'+this.id,
            {
                withCredentials: true
            })
            .then(response => {
                if(response.status == 200){
                    var object = response.data
                    object.product ? this.product = new Product(object.product) : this.product = null
                    object.productCount ? this.productCount = object.productCount : this.productCount = null
                    object.parentLinear ? this.parentLinear = new Linear(object.parentLinear) : this.parentLinear = null
                    object.moduleOrder ? this.moduleOrder = object.moduleOrder : this.moduleOrder = null
                }
            })

        }
    }

    getLocation(){
        var location = this.parentLinear.getLocation()
        location['moduleOrder'] = this.moduleOrder
        return location
    }
}