// src/plugins/devicePlugin.js

export default {
    install(app) {
      const device = {
        // Logic to detect if the device is mobile
        get isMobile() {
          return window.innerWidth <= 768; // Example breakpoint for mobile
        },
        // Logic to detect orientation
        get isPortrait() {
          return window.innerHeight > window.innerWidth;
        },
      };
  
      // Provide the device globally
      app.config.globalProperties.$device = device;
    },
  };
  