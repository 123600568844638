<template lang="html">
  <button
    class="rounded-md transition-all box-border flex justify-around items-center shadow-sm hover:shadow-lg active:shadow-xs"
    :class="styling(styles.button)" :disabled="state === 'disabled' ? 'disabled' : null">
    <component v-if="icon" 
    :is="filling == 'filled'? heroIconsSolid[icon] :heroIconsOutline[icon]" 
    fill="currentColor" 
    class="stroke-1" 
    :class="styling(styles.icon)" />
    <span v-if="label">
      {{ label }}
    </span>
  </button>
</template>
  
<script>

import * as heroIconsOutline from '@heroicons/vue/24/outline'
import * as heroIconsSolid from '@heroicons/vue/24/solid'

export default {
  name: 'ButtonVue',
  props: {
    label: {
      type: String,
      default: ""
    },
    filling: {
      type: String,
      validator(value) {
        return ['filled', 'outlined', 'text'].includes(value)
      },
      default: 'filled'
    },
    height: {
      type: String,
      validator(value) {
        return ['large', 'medium', 'small'].includes(value)
      },
      default: 'large'
    },
    icon: {
      type: String,
      default: null
    },
    icon_position: {
      type: String,
      validator(value) {
        return ['left', 'right'].includes(value)
      },
      default: 'right'
    },
    state: {
      type: String,
      validator(value) {
        return ['default', 'success', 'danger', 'warning', 'neutral', 'disabled'].includes(value)
      },
      default: 'default'
    },
  },
  watch: {
    label: {
      handler() { },
      deep: true
    },
    filling: {
      handler() { },
      deep: true
    },
    height: {
      handler() { },
      deep: true
    },
    icon: {
      handler() { },
      deep: true
    },
    icon_position: {
      handler() { },
      deep: true
    },
    state: {
      handler() { },
      deep: true
    },
  },
  data() {
    return {
      heroIconsSolid: heroIconsSolid,
      heroIconsOutline:heroIconsOutline,
      styles: {
        'button': {
          'height': {
            'large': 'text-body-1 px-3 border h-button-large',
            'medium': 'text-body-4 px-2 border h-button-medium',
            'small': 'text-body-5 px-1 border h-button-small',
          },
          'filling': {
            'filled': {
              'default': 'text-white bg-primary-500 border-primary-500 hover:bg-primary-400 active:bg-primary-600 hover:border-primary-400 active:border-primary-600',
              'success': 'text-white bg-success-500 border-success-500 hover:bg-success-400 active:bg-success-600 hover:border-success-400 active:border-success-600',
              'danger': 'text-white bg-danger-500 border-danger-500 hover:bg-danger-400 active:bg-danger-600 hover:border-danger-400 active:border-danger-600',
              'warning': 'text-white bg-warning-500 border-warning-500 hover:bg-warning-400 active:bg-warning-600 hover:border-warning-400 active:border-warning-600',
              'neutral': 'text-white bg-neutral-500 border-neutral-500 hover:bg-neutral-400 active:bg-neutral-600 hover:border-neutral-400 active:border-neutral-600',
              'disabled': 'border text-neutral-500 bg-neutral-300 border-neutral-300'
            },
            'outlined': {
              'default': 'text-primary-500 border-primary-500 bg-transparent hover:bg-primary-300 hover:text-white hover:border-primary-300 active:bg-primary-500 active:text-white active:border-primary-500',
              'success': 'text-success-500 border-success-500 bg-transparent hover:bg-success-300 hover:text-white hover:border-success-300 active:bg-success-500 active:text-white active:border-success-500',
              'danger': 'text-danger-500 border-danger-500 bg-transparent hover:bg-danger-300 hover:text-white hover:border-danger-300 active:bg-danger-500 active:text-white active:border-danger-500',
              'warning': 'text-warning-500 border-warning-500 bg-transparent hover:bg-warning-300 hover:text-white hover:border-warning-300 active:bg-warning-500 active:text-white active:border-warning-500',
              'neutral': 'text-neutral-500 border-neutral-500 bg-transparent hover:bg-neutral-300 hover:text-white hover:border-neutral-300 active:bg-neutral-500 active:text-white active:border-neutral-500',
              'disabled': 'border text-neutral-500 bg-white border-neutral-300',
            },
            'text': {
              'default': 'text-primary-500 border-transparent bg-transparent hover:text-primary-400 active:text-primary-600',
              'success': 'text-success-500 border-transparent bg-transparent hover:text-success-400 active:text-success-600',
              'danger': 'text-danger-500 border-transparent bg-transparent hover:text-danger-400 active:text-danger-600',
              'warning': 'text-warning-500 border-transparent bg-transparent hover:text-warning-400 active:text-warning-600',
              'neutral': 'text-neutral-500 border-transparent bg-transparent hover:text-neutral-400 active:text-neutral-600',
              'disabled': 'border text-neutral-500 bg-white border-white',
            }
          },
          'icon_position': {
            'left': 'flex-row',
            'right': 'flex-row-reverse',
          },
        },
        'icon': {
          'height': {
            'large': 'h-6 w-6',
            'medium': 'h-5 w-5',
            'small': 'h-4 w-4',
          },
        }
      }
    }
  },
  methods: {
    getHeight(object) {
      switch (this.height) {
        case 'large': {
          return object.large
        }
        case 'medium': {
          return object.medium
        }
        case 'small': {
          return object.small
        }
        default: {
          return ""
        }
      }
    },
    getFilling(object) {
      switch (this.filling) {
        case 'filled': {
          switch (this.state) {
            case 'default': {
              return object.filled.default
            }
            case 'success': {
              return object.filled.success
            }
            case 'danger': {
              return object.filled.danger
            }
            case 'warning': {
              return object.filled.warning
            }
            case 'disabled': {
              return object.filled.disabled
            }
            default: {
              return object.filled.neutral
            }
          }
        }
        case 'outlined': {
          switch (this.state) {
            case 'default': {
              return object.outlined.default
            }
            case 'success': {
              return object.outlined.success
            }
            case 'danger': {
              return object.outlined.danger
            }
            case 'warning': {
              return object.outlined.warning
            }
            case 'disabled': {
              return object.outlined.disabled
            }
            default: {
              return object.outlined.neutral
            }
          }
        }
        case 'text': {
          switch (this.state) {
            case 'default': {
              return object.text.default
            }
            case 'success': {
              return object.text.success
            }
            case 'danger': {
              return object.text.danger
            }
            case 'warning': {
              return object.text.warning
            }
            case 'disabled': {
              return object.text.disabled
            }
            default: {
              return object.text.neutral
            }
          }
        }
        default: {
          return object.filled.neutral
        }
      }
    },
    getIconPosition(object) {
      switch (this.icon_position) {
        case 'left': {
          return object.left
        }
        case 'right': {
          return object.right
        }
        default: {
          return ""
        }
      }
    },
    styling(object) {
      var valren = ''
      object.height ? valren += this.getHeight(object.height) + ' ' : null
      object.filling ? valren += this.getFilling(object.filling) + ' ' : null
      object.icon_position ? valren += this.getIconPosition(object.icon_position) + ' ' : null
      return valren
    }
  },
}


</script>
    