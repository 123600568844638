<template>
    <div class="flex flex-col relative overflow-x-auto sm:rounded-lg container shadow-lg h-full w-full mx-auto">
        <LoadingVue v-if="!loaded && !linears" />
        <div v-else class="p-2 w-full h-full">
            <div v-for="linear in linears" :key="linear.id"
                class="flex flex-col gap-y-4 lg:justify-between justify-start h-[25%]">
                <div class="flex flex-row justify-start relative w-full pt-2">
                    <div class="flex flex-col items-center justify-end mx-2"
                        v-for="(section, index) in linear.modules" :key="index">
                        <div class="min-w-[150px] max-h-[15vh] min-h-[15vh] flex flex-col justify-end"
                            :class="[section.selected ? 'bg-primary-100' : null, section.products.length > 0 && section.productCount < 1 ? 'border-[6px] !border-danger-600' : 'border-b-[6px] border-gray-400', section.products.length > 0 && section.productCount < section.products[0].minProduct && section.productCount > 1 ? 'border-[6px] !border-warning-600' : 'border-b-[6px] border-gray-400']">
                            <div v-for="product in section.products" :key="product.id"
                                class="flex flex-col justify-end items-center p-2 max-h-[15vh] w-full"
                                @click="productInformation = true; currentProduct = product">
                                <!-- <img :src="product.imageUrl" alt="" class="w-[50%]"> -->
                                <div>{{ product.reference }}</div>
                                <div>{{ product.barCode }}</div>
                                <div class="rounded-[100px] shadow-xl w-[4rem] h-[1.5rem] text-white flex flex-col items-center justify-center mb-2" :class="section.productCount && section.productCount > 0 ? 'bg-primary-800' : 'bg-danger-800'">
                                    {{ section.productCount ? section.productCount : 0 }}
                                </div>
                            </div>
                            <div v-if="!section.products.length" class="flex flex-col justify-end items-center p-2 max-h-[15vh] w-full">
                                <div>Pas de Produit</div>
                            </div>
                        </div>
                    </div>
                </div>
                <router-link :to="'/shelf/' + linear.serialNumber" class="flex justify-center mr-auto">
                    <div
                        class="flex flex-row items-center rounded-md border border-primary-500 bg-primary-500 hover:bg-primary-400 hover:border-primary-400 shadow-xl hover:shadow-lg p-1 text-white ml-3">
                        <component :is="heroIcons['ChevronRightIcon']" fill="white" class="stroke-2 h-[1rem]" />
                        <p class="text-[12px]">Réglages</p>
                    </div>
                </router-link>
            </div>
        </div>
    </div>

</template>

<script>
import Linear from '@/utils/entities/Linear';
import LoadingVue from '../elements/Loading.vue';
import WebsocketService from '@/utils/WebsocketService'; // Import WebSocketService

import * as heroIcons from '@heroicons/vue/24/outline';

export default {
    name: 'ShelfViewer',
    components: {
        LoadingVue,
    },
    props: {
        aisle: {
            type: Number,
            required: true,
        },
        position: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            heroIcons: heroIcons,
            loaded: false,
            linears: [],
            subscribedLinears: new Set(),
            alreadySubscribed: false,
        };
    },

    async mounted() {

        WebsocketService.initializeWebSocket(process.env.VUE_APP_WEBSOCKET_PATH);

        await this.getLinears().then(async () => {
            await this.waitForWebSocketConnection().then(() => {
                this.checkAndSubscribe();
            });    
        });

        this.loaded = true;
        },
    beforeUnmount() {
        this.linears.forEach(linear => {
            linear.unsubscribeWebSocket()
        })
        this.subscribedLinears=new Set();
    },
    watch:{
        aisle(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.getLinears();
            }
        },
        position(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.getLinears();
            }
        },
      linears:{
        handler(newLinears, oldLinears) {
            if(this.alreadySubscribed){
                oldLinears.forEach((linear) => {
                    if (!newLinears.includes(linear)) {
                        linear.unsubscribeWebSocket();
                    this.subscribedLinears.delete(linear); // Remove from the Set
                    }
                });

                newLinears.forEach((linear) => {
                    if (!this.subscribedLinears.has(linear)) {
                        linear.initializeWebSocketListener();
                        this.subscribedLinears.add(linear); // Add to the Set to track subscription
                    }
                });
            }
        },
        deep: true,
      }
    },
    methods: {
      async waitForWebSocketConnection() {
            return new Promise((resolve) => {
            const interval = setInterval(() => {
                if (WebsocketService.isConnected) {
                clearInterval(interval);
                resolve();
                }
            }, 1000); // Check every 1 second
            });
        },
        checkAndSubscribe() {
            this.linears.forEach((linear) => {
                if (!this.subscribedLinears.has(linear)) {
                    linear.initializeWebSocketListener();
                    this.subscribedLinears.add(linear); // Add to the Set to track subscription
                }
            });

            this.alreadySubscribed = true
        },
        async getLinears() {
            try {
                const response = await this.$axios.get(
                    `${process.env.VUE_APP_API_BASE_PATH}/linears?aisle=${this.aisle}&position=${this.position}`,
                    {
                        withCredentials: true,
                    }
                );
                if (response.status === 200) {
                    this.linears = response.data['hydra:member']
                        .sort((a, b) => b.storey - a.storey)
                        .map((linear) => {
                            linear.modules.sort((a, b) => a.moduleOrder - b.moduleOrder);
                            return new Linear(linear);
                        });

                    // Initialize product minProduct if needed
                    this.linears.forEach((linear) => {
                        linear.modules.forEach((module) => {
                            module.products.forEach((product) => {
                                if (product.minProduct === 'N/A') {
                                    product.minProduct = 5;
                                }
                            });
                        });
                    });
                }
            } catch (error) {
                console.error('Error fetching linears:', error);
            }
        },
    },
};
</script>
