<template lang="html">
    <div class="flex flex-col justify-start align-top">
        <span v-if="label" class="mb-1 mx-1 text-neutral-700" 
        :class="styling(styles.span_label)">
            {{label}}
        </span>
        <div class="flex relative">
            <select 
            v-model="dataValue"
            @change="$emit('update:modelValue', dataValue)" 
            class="rounded-md text-neutral-600 box-border border transition-all w-full outline-none focus:ring-1 focus:ring-inset focus:ring-offset-1 focus:border-primary-400 focus:ring-primary-400 shadow-sm hover:shadow-lg focus:shadow-xs appearance-none"
            :class="styling(styles.select)"
            :disabled="state === 'disabled' ? 'disabled' : null"
            >
                <option disabled value="-1">{{ placeholder }}</option>
                <option 
                v-for="(option,key) in options" 
                :key="key" 
                :value="option.value">
                    {{ option.name }}
                </option>
            </select>
            <component
            :is="heroIcons['ChevronDownIcon']"
            class="absolute pointer-events-none stroke-1 stroke-neutral-600" 
            :class="styling(styles.icon_chevron)"/>
        </div>
        <span v-if="message" 
        class="mt-1 mx-1 inline-flex items-center" 
        :class="styling(styles.span_message)">
            <component
            :is="heroIcons[styles.icon_message[state]]" 
            fill="currentColor" 
            class="mr-1" 
            :class="styling(styles.icon_message)"/>
            {{message}}
        </span>
        <span v-if="helper" 
        class="mt-1 mx-1 text-neutral-400" 
        :class="styling(styles.span_helper)">
            {{helper}}
        </span>
    </div>
</template>
  
<script>
import * as heroIcons from '@heroicons/vue/24/outline'

export default  {
    name: 'SelectVue',
    props: {
        height: {
            type: String,
            validator(value) {
                return ['large', 'medium', 'small'].includes(value)
            }, 
            default: 'medium'
        },
        placeholder: {
            type: String,
            default: 'Insert text here'
        },
        label: {
            type: String,
            default: ''
        },
        message: {
            type: String,
            default: ''
        },
        helper: {
            type: String,
            default: ''
        },
        state: {
            type: String,
            validator(value) {
                return ['default', 'success', 'danger', 'warning', 'disabled'].includes(value)
            }, 
            default: 'default'
        },
        options: {
            type: Array, 
            default: () => {
                return []
            }
        },
        modelValue: {
            default: -1
        }
    },
    created(){
        this.dataValue = this.modelValue
    },
    data () {
        return {
            heroIcons: heroIcons,
            dataValue: null,
            styles: {
                'span_label' : {
                    'height' : {
                        'large': 'text-body-5 text-neutral-600',
                        'medium': 'text-body-6 text-neutral-600',
                        'small': 'text-body-7 text-neutral-600',
                    },
                },
                'select' : {
                    'height' : {
                        'large': 'h-text-input-large text-body-2 pl-[0.5rem]',  
                        'medium': 'h-text-input-medium text-body-5 pl-[0.75rem]', 
                        'small': 'h-text-input-small text-body-6 pl-[1rem]',
                    },
                    'state' : {
                        'default': 'border-neutral-500 hover:border-neutral-300',
                        'success': 'border-success-500 hover:border-success-300',
                        'danger': 'border-danger-500 hover:border-danger-300',
                        'warning': 'border-warning-500 hover:border-warning-300',
                        'disabled': 'border-neutral-500 bg-neutral-400/10',
                    }
                },
                'icon_chevron' : {
                    'height' : {
                        'large': 'h-6 w-6 right-[0.75rem] inset-y-[0.75rem]',
                        'medium': 'h-5 w-5 right-[0.65rem] inset-y-[0.65rem]',
                        'small': 'h-4 w-4 right-[0.5rem] inset-y-[0.5rem]',
                    },
                },
                'span_message' : {
                    'height' : {
                        'large': 'text-body-5 text-neutral-600',
                        'medium': 'text-body-6 text-neutral-600',
                        'small': 'text-body-7 text-neutral-600',
                    },
                    'state' : {
                        'default': 'text-neutral-600',
                        'success': 'text-success-600',
                        'danger': 'text-danger-600',
                        'warning': 'text-warning-600',
                        'disabled': 'text-neutral-600',
                    }
                },
                'icon_message' : {
                    'height' : {
                        'large': 'h-5 w-5',
                        'medium': 'h-3 w-3',
                        'small': '',
                    },
                    'state' : {
                        'default': '',
                        'success': 'CheckCircleIcon',
                        'danger': 'XCircleIcon',
                        'warning': 'ExclamationIcon',
                        'disabled': '',
                    }
                },
                'span_helper' : {
                    'height' : {
                        'large': 'text-body-5 text-neutral-600',
                        'medium': 'text-body-6 text-neutral-600',
                        'small': 'text-body-7 text-neutral-600',
                    },
                },
            }
        }
    },
    methods: {
        getState(object){
            switch (this.state){
                case 'default':{
                    return object.default
                }
                case 'success':{
                    return object.success
                }
                case 'danger':{
                    return object.danger
                }
                case 'warning':{
                    return object.warning
                }
                case 'disabled':{
                    return object.disabled
                }
            }
        },
        getHeight(object){
            switch (this.height){
                case 'large':{
                    return object.large
                }
                case 'medium':{
                    return object.medium
                }
                case 'small':{
                    return object.small
                }
            }
        },
        styling(object){
            var valren = ''
            object.height ? valren += this.getHeight(object.height) +' ' : null
            object.state ? valren += this.getState(object.state) +' ' : null
            return valren
        }
    },
    watch:{
        modelValue: {
            handler() {
                this.dataValue = this.modelValue
            },
            deep: true
        },
        height: {
            handler() {},
            deep: true
        },
        placeholder: {
            handler() {},
            deep: true
        },
        label: {
            handler() {},
            deep: true
        },
        message: {
            handler() {},
            deep: true
        },
        helper: {
            handler() {},
            deep: true
        },
        state: {
            handler() {},
            deep: true
        },
        options: {
            handler() {},
            deep: true
        },
    }
  }
  
  
  </script>
  