<template>
    <div class="flex flex-col relative overflow-x-auto sm:rounded-lg container shadow-lg h-[100vh] w-full mx-auto top-[100px]">
      <LoadingVue v-if="!loaded && !linears" />
      <div v-else :class="isMobile ? 'flex flex-col p-1 w-full gap-y-4' : 'p-6 w-full'">
        <div class="flex flex-row w-full justify-center">
          <ul class="inline-flex items-center -space-x-px">
            <li
              class="border border-gray-400 rounded-l-md p-1 text-gray-400 hover:bg-gray-300 hover:text-white hover:border-gray-300"
              @click="previousPage()"
            >
              <component :is="heroIcons['ChevronLeftIcon']" fill="none" class="stroke-1 h-7 w-7" />
            </li>
            <li
              class="border border-gray-400 p-1 text-body-2 text-gray-400 hover:bg-gray-300 hover:text-white hover:border-gray-300"
            >
              <div class="h-7 min-w-[50px] px-1 flex items-center justify-center">
                Allée : {{ currentAisle }} - Rang : {{ currentPosition }}
              </div>
            </li>
            <li
              class="border border-gray-400 rounded-r-md p-1 text-gray-400 hover:bg-gray-300 hover:text-white hover:border-gray-300"
              @click="nextPage()"
            >
              <component :is="heroIcons['ChevronRightIcon']" fill="none" class="stroke-1 h-7 w-7" />
            </li>
          </ul>
        </div>
        <div
          v-for="linear in linears"
          :key="linear.id"
          class="flex flex-col gap-y-4 lg:justify-between justify-start h-[25%]"
        >
          <div class="flex flex-row justify-between relative overflow-scroll w-full pt-2">
            <div
              class="flex flex-col w-12% items-center justify-end"
              v-for="(section, index) in linear.modules"
              :key="index"
            >
              <div
                class="w-full h-full flex flex-col justify-end"
                :class="[
                  section.selected ? 'bg-primary-100' : null,
                  section.products.length > 0 && section.productCount < 1 ? 'border-[6px] border-danger-600' : '',
                  section.products.length > 0 && section.productCount < section.products[0].minProduct && section.productCount > 1 ? 'border-[6px] border-warning-600' : ''
                ]"
                @click="log(section.products[0].minProduct)"
              >
                <div
                  v-for="product in section.products"
                  :key="product.id"
                  class="w-full h-full flex flex-col justify-end items-center"
                  @click="showProductInformation(product)"
                >
                  <img :src="product.imageUrl" alt="" class="w-[8vw]" />
                </div>
              </div>
            </div>
          </div>
          <div class="flex flex-col justify-center align-end items-center h-full ml-auto">
            <router-link :to="'/shelf/' + linear.serialNumber">
              <div
                class="flex flex-row items-center rounded-md border border-primary-500 bg-primary-500 hover:bg-primary-400 hover:border-primary-400 shadow-xl hover:shadow-lg p-1 text-white"
              >
                <component :is="heroIcons['ChevronRightIcon']" fill="white" class="stroke-2 h-[1rem]" />
                <p class="text-[12px]">Réglages</p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <ModalVue
        v-show="productInformation"
        :title="'Détails du Produit'"
        :confirmButtonLabel="'MODIFIER'"
        @closeModal="productInformation = false"
        @confirm="redirectToProduct"
      >
        <template v-slot:body>
          <div class="flex flex-col w-full">
            <div class="flex flex-row justify-around w-full min-h-[100px]">
              <div class="flex flex-col justify-end">
                <div
                  class="rounded-[50px] shadow-xl w-[4rem] h-[1.5rem] text-white flex flex-col items-center justify-center mb-2"
                  :class="currentProduct.productCount && currentProduct.productCount > 0 ? 'bg-primary-800' : 'bg-danger-800'"
                >
                  {{ currentProduct.productCount ? currentProduct.productCount : 0 }}
                </div>
                <div
                  class="rounded-[50px] shadow-xl w-[4rem] h-[1.5rem] text-black flex flex-col items-center justify-center bg-gray-300"
                >
                  {{ currentProduct.weight ? currentProduct.weight : 0 }}
                </div>
              </div>
              <div class="flex justify-center w-70%">
                <img :src="currentProduct.imageUrl" alt="" class="max-h-[200px]" />
              </div>
            </div>
            <div class="flex flex-col mt-4">
              <p class="font-bold">{{ currentProduct.brand }}</p>
              <p>{{ currentProduct.reference }}</p>
            </div>
          </div>
        </template>
      </ModalVue>
    </div>
  </template>
  
  <script>
  import Linear from '@/utils/entities/Linear';
  import LoadingVue from '../components/elements/Loading.vue';
  import ModalVue from '../components/containers/Modal.vue';
  import WebsocketService from '@/utils/WebsocketService'; // Import WebsocketService
  import * as heroIcons from '@heroicons/vue/24/outline';
  
  export default {
    name: 'ShelfViewerView',
    components: {
      LoadingVue,
      ModalVue,
    },
    data() {
      return {
        heroIcons,
        loaded: false,
        linears: [],
        productInformation: false,
        currentProduct: null,
      };
    },
    computed: {
        isMobile() {
        return this.$device.isMobile;
        },
        isPortrait() {
        return this.$device.isPortrait;
        },
    },
    async mounted() {
      await this.getLinears();
      this.setupWebSocket();
      this.initializeProducts();
      this.loaded = true;
      this.$emit('loaded');
    },
    unmounted() {
      this.cleanupWebSocket();
    },
    methods: {
      setupWebSocket() {
        WebsocketService.initializeWebSocket(process.env.VUE_APP_WEBSOCKET_PATH);
  
        WebsocketService.addListener((type, data) => {
          if (type === 'message') {
            this.handleWebSocketMessage(data);
          }
        });
  
        WebsocketService.addListener((type) => {
          if (type === 'open') {
            this.linears.forEach(linear => {
              WebsocketService.sendMessage({
                command: 'subscribe',
                resource: `/api/linears/${linear.serialNumber}`,
              });
              WebsocketService.sendMessage({
                command: 'read_weight',
                resource: `/api/linears/${linear.serialNumber}`,
                value: {
                  module0: true,
                  module1: true,
                  module2: true,
                  module3: true,
                  module4: true,
                  module5: true,
                  module6: true,
                },
              });
            });
          }
        });
      },
      cleanupWebSocket() {
        this.linears.forEach(linear => {
          WebsocketService.sendMessage({
            command: 'unsubscribe',
            resource: `/api/linears/${linear.serialNumber}`,
          });
        });
      },
      handleWebSocketMessage(data) {
        const resourceArray = data.resource.split('/');
        if (resourceArray.length > 2 && resourceArray[1] === 'api' && resourceArray[2] === 'linears') {
          const serialNumber = resourceArray[3];
          const linearIndex = this.linears.findIndex(linear => linear.serialNumber === serialNumber);
          if (linearIndex !== -1 && data.command === 'read_weight') {
            data.value.forEach(element => {
              const moduleIndex = this.linears[linearIndex].modules.findIndex(module => module.module.id === element.module);
              if (moduleIndex !== -1) {
                this.linears[linearIndex].modules[moduleIndex].module.weight = element.weight;
                this.linears[linearIndex].modules[moduleIndex].module.productCount = element.productCount || 0;
              }
            });
          }
          this.$forceUpdate();
        }
      },
      async getLinears() {
        try {
          const response = await this.$axios.get(`${process.env.VUE_APP_API_BASE_PATH}/linears${window.location.search}`, {
            withCredentials: true,
          });
          if (response.status === 200) {
            this.linears = response.data['hydra:member']
              .sort((a, b) => b.storey - a.storey)
              .map(linear => {
                linear.modules.sort((a, b) => a.moduleOrder - b.moduleOrder);
                return new Linear(linear);
              });
          }
        } catch (error) {
          console.error('Error fetching linears:', error);
        }
      },
      initializeProducts() {
        this.linears.forEach(linear => {
          linear.modules.forEach(module => {
            module.products.forEach(product => {
              if (product.minProduct === 'N/A') {
                product.minProduct = 5;
              }
            });
          });
        });
      },
      async nextPage() {
        await this.$router.push("/shelf_viewer?aisle=4&position=2");
      },
      async previousPage() {
        await this.$router.push("/shelf_viewer?aisle=4&position=1");
      },
      redirectToProduct() {
        this.$router.push(`/product/${this.currentProduct.barCode}`);
      },
      showProductInformation(product) {
        this.currentProduct = product;
        this.productInformation = true;
      },
      log(x) {
        console.log(x);
      },
    },
  };
  </script>
  