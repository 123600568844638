<template class="mx-auto">
    <div v-if="isMobile" class="w-full h-full flex flex-row overflow-x-hidden bg-gray-50 relative top-[100px]">
        <!-- Mobile View -->
        <div class="w-full">
            <LoadingVue v-if="!product" />
            <div v-else class="w-full flex-col flex">
                <div id="ProductInfo">
                    <div @click="toggleBody('ProductInfoBody')" id="ProductInfoHead"
                        class="border-b-2 border-gray-200 px-4 py-2 flex flex-row justify-between items-center"
                        :class="{ 'bg-gray-200': bodyVisibility.ProductInfoBodyBody }">
                        <p class="text-md font-semibold">Produit</p>
                        <ButtonVue :filling="'text'" :label="null" :icon="'ChevronDownIcon'" :height="'medium'"
                            :state="'neutral'" />
                    </div>
                    <div v-show="bodyVisibility.ProductInfoBody" id="ProductInfoBody"
                        class="px-6 py-4 border-b-2 border-gray-200">
                        <div class="flex flex-col items-center">
                            <div v-if="product" class="w-50% flex flex-row items-center justify-center">
                                <img :src="product.imageUrl" class="!max-w-[40%] " />
                            </div>
                            <div>
                                <input type="file" @input="saveFile($event)" accept="image/*,.pdf" class="block w-full text-body-5 text-neutral-500
                                file:mr-4 file:py-2 file:px-4
                                file:rounded-full file:border-0
                                file:text-body-5 file:font-semibold
                                file:bg-primary-100 file:text-white
                                hover:file:bg-primary-100 mt-4
                            " />
                                <InputVue class="w-full mt-2" :label="'Marque'" :placeholder="'Marque'"
                                    v-model="product.brand" :height="'large'" :state="productBrandState"
                                    :message="productBrandMessage" />
                                <InputVue class="w-full mt-2" :label="'Reference'" :placeholder="'Reference'"
                                    v-model="product.reference" :height="'large'" :state="productReferenceState"
                                    :message="productReferenceMessage" />
                                <SelectVue class="w-full mt-2" v-model="product.section" :options="sections"
                                    :placeholder="'Choisir une option...'" :label="'Rayon'" :height="'large'"
                                    :state="'default'" />
                                <InputVue class="w-full mt-2" :label="'Poids Net'" :placeholder="'Poids Net'"
                                    v-model="product.netWeight" :height="'large'" :state="productNetWeightState"
                                    :message="productNetWeightMessage" />
                                <InputVue class="w-full mt-2" :label="'Poids'" :placeholder="'Poids'"
                                    v-model="product.weight" :height="'large'" :state="productWeightState"
                                    :message="productWeightMessage" />
                                <InputVue class="w-full mt-2" :label="'SMP'" :placeholder="'SMP'"
                                    v-model="product.minProduct" :height="'large'" :state="minProductState"
                                    :message="minProductMessage" />
                                <CheckboxVue class="w-full mt-2" :label="'Est un produit sensible ?'"
                                    v-model="product.isSensitive" :height="'large'" :state="isSensitiveState"
                                    :message="isSensitiveMessage" />
                                <ButtonVue class="w-full mt-5" :filling="'filled'" :label="'Enregistrer Produit'"
                                    :height="'large'" :state="'default'" @click="saveProduct()" />
                            </div>
                        </div>
                    </div>
                    <div id="RefDataset">
                        <div @click="toggleBody('RefDatasetBody')" id="RefDatasetHead"
                            class="border-b-2 border-gray-200 px-4 py-2 flex flex-row justify-between items-center"
                            :class="{ 'bg-gray-200': bodyVisibility.RefDatasetBody }">
                            <p class="text-md font-semibold">Dataset de référence</p>
                            <ButtonVue :filling="'text'" :label="null"
                                :icon="bodyVisibility.RefDatasetBody ? 'ChevronUpIcon' : 'ChevronDownIcon'"
                                :height="'medium'" :state="'neutral'" />
                        </div>
                        <div v-show="bodyVisibility.RefDatasetBody" id="RefDatasetBody"
                            class="pb-4 border-b-2 border-gray-200">
                            <div class="flex flex-col w-full items-center">
                                <AutocompleteVue class="w-full p-5 flex-grow" v-model="product.filters[0].barCode"
                                    :height="'large'" :label="'Produit'" :placeholder="'Marque Référence'"
                                    :state="'disabled'" />
                                <SelectVue class="w-full px-5 pb-5" v-model="product.filters[0].timePeriod"
                                    :options="timePeriodOptions" :placeholder="'Choisir une option...'"
                                    :label="'Periode'" :height="'large'" @update:modelValue="async () => {
                                        product.filters[1].timePeriod = product.filters[0].timePeriod
                                        chartData = false
                                        await product.getEvents()
                                        chartData = true
                                    }" />
                                <DatetimefieldVue class="w-full px-5 pb-5 "
                                    v-model="product.filters[0]['createdAt[after]']"
                                    :placeholder="'Choisir une date...'" :label="'Depuis'" :height="'large'"
                                    :top="false" @update:modelValue="async () => {
                                        if (product.filters[0].barCode != product.filters[1].barCode) {
                                            product.filters[1]['createdAt[after]'] = product.filters[0]['createdAt[after]']
                                        }
                                        chartData = false
                                        await product.getEvents()
                                        chartData = true
                                    }" />
                                <DatetimefieldVue class="w-full px-5 pb-5 "
                                    v-model="product.filters[0]['createdAt[before]']"
                                    :placeholder="'Choisir une date...'" :label="'jusqu\'à'" :height="'large'"
                                    :top="false" @update:modelValue="async () => {
                                        if (product.filters[0].barCode != product.filters[1].barCode) {
                                            product.filters[1]['createdAt[before]'] = product.filters[0]['createdAt[before]']
                                        }
                                        chartData = false
                                        await product.getEvents()
                                        chartData = true
                                    }" />
                                <ButtonVue class="!w-[90%]" :label="'Extrait Excel'" :height="'large'"
                                    @click="product.getEventsExtract(0)" />
                            </div>
                        </div>
                    </div>
                    <div id="CompDataset">
                        <div @click="toggleBody('CompDatasetBody')" id="CompDatasetHead"
                            class="border-b-2 border-gray-200 px-4 py-2 flex flex-row justify-between items-center"
                            :class="{ 'bg-gray-200': bodyVisibility.CompDatasetBody }">
                            <p class="text-md font-semibold">Dataset de comparaison</p>
                            <ButtonVue :filling="'text'" :label="null" :icon="'ChevronDownIcon'" :height="'medium'"
                                :state="'neutral'" />
                        </div>
                        <div v-show="bodyVisibility.CompDatasetBody" id="CompDatasetBody"
                            class="pb-4 border-b-2 border-gray-200">
                            <div class="flex flex-col w-full items-center">
                                <AutocompleteVue class="w-full p-5 flex-grow" v-model="brandAndRef" :height="'large'"
                                    :label="'Rechercher un produit'" :placeholder="'Marque Référence'"
                                    :objects="products" @update:modelValue="getProducts()" @setValue="async (e) => {
                                        setProduct(e)
                                        product.filters[1].barCode = e.barCode
                                        product.filters[1].resource = '/api/products/' + e.barCode
                                        product.filters[1]['createdAt[after]'] = product.filters[0]['createdAt[after]']
                                        product.filters[1]['createdAt[before]'] = product.filters[0]['createdAt[before]']
                                        chartData = false
                                        await product.getEvents()
                                        chartData = true
                                    }" />
                                <SelectVue class="w-full px-5 pb-5" v-model="product.filters[1].timePeriod"
                                    :options="timePeriodOptions" :placeholder="'Choisir une option...'"
                                    :label="'Periode'" :height="'large'" :state="'disabled'" @update:modelValue="async () => {
                                        chartData = false
                                        await product.getEvents()
                                        chartData = true
                                    }" />
                                <DatetimefieldVue class="w-full px-5 pb-5"
                                    v-model="product.filters[1]['createdAt[after]']"
                                    :placeholder="'Choisir une date...'" :label="'Depuis'" :height="'large'"
                                    :top="false" @update:modelValue="async () => {
                                        chartData = false
                                        await product.getEvents()
                                        chartData = true
                                    }" />
                                <DatetimefieldVue class="w-full px-5 pb-5"
                                    v-model="product.filters[1]['createdAt[before]']"
                                    :placeholder="'Choisir une date...'" :label="'jusqu\'à'" :height="'large'"
                                    :top="false" @update:modelValue="async () => {
                                        chartData = false
                                        await product.getEvents()
                                        chartData = true
                                    }" />
                                <ButtonVue class="!w-[90%]" :label="'Extrait Excel'" :height="'large'"
                                    @click="product.getEventsExtract(1)" />
                            </div>
                        </div>
                    </div>
                    <div id="Graph"
                        class="min-w-[300px] max-h-[50vh] w-75% mx-auto my-6 p-6 bg-white rounded-lg shadow-lg">
                        <Bar v-if="chartData" :data="product.events" />
                        <LoadingVue v-else />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="w-full h-full flex flex-row overflow-x-hidden bg-gray-50 relative top-[100px]">
        <!-- Desktop View -->
        <div class="w-[34%] h-[calc(100vh-100px)] shadow-sm p-4 bg-gray-50">
            <LoadingVue v-if="!product" />
            <div v-else class="h-full flex flex-col justify-between items-center">
                <div>
                    <span class="text-title-2">
                        {{ product.barCode }}
                    </span>
                </div>
                <div class="flex flex-col items-center">
                    <div v-if="product" class="w-50% flex flex-row items-center justify-center mt-5">
                        <img :src="product.imageUrl" />
                    </div>
                    <div>
                        <input type="file" @input="saveFile($event)" accept="image/*,.pdf" class="block w-full text-body-5 text-neutral-500
                                file:mr-4 file:py-2 file:px-4
                                file:rounded-full file:border-0
                                file:text-body-5 file:font-semibold
                                file:bg-primary-100 file:text-white
                                hover:file:bg-primary-100 mt-10
                            " />
                        <InputVue class="w-full mt-2" :label="'Marque'" :placeholder="'Marque'" v-model="product.brand"
                            :height="'large'" :state="productBrandState" :message="productBrandMessage" />
                        <InputVue class="w-full mt-2" :label="'Reference'" :placeholder="'Reference'"
                            v-model="product.reference" :height="'large'" :state="productReferenceState"
                            :message="productReferenceMessage" />
                        <SelectVue class="w-full mt-2" v-model="product.section" :options="sections"
                            :placeholder="'Choisir une option...'" :label="'Rayon'" :height="'large'"
                            :state="'default'" />
                        <InputVue class="w-full mt-2" :label="'Poids Net'" :placeholder="'Poids Net'"
                            v-model="product.netWeight" :height="'large'" :state="productNetWeightState"
                            :message="productNetWeightMessage" />
                        <InputVue class="w-full mt-2" :label="'Poids'" :placeholder="'Poids'" v-model="product.weight"
                            :height="'large'" :state="productWeightState" :message="productWeightMessage" />
                        <InputVue class="w-full mt-2" :label="'SMP'" :placeholder="'SMP'" v-model="product.minProduct"
                            :height="'large'" :state="minProductState" :message="minProductMessage" />
                        <InputVue class="w-full mt-2" :label="'Delais de peremption moyen'" :placeholder="'En jours'" v-model="product.expiringOffset"
                            :height="'large'" :state="expiringOffsetState" :message="expiringOffsetMessage" />
                        <CheckboxVue class="w-full mt-2" :label="'Est un produit sensible ?'"
                            v-model="product.isSensitive" :height="'large'" :state="isSensitiveState"
                            :message="isSensitiveMessage" />
                        <ButtonVue class="w-full mt-10" :filling="'filled'" :label="'Enregistrer Produit'"
                            :height="'large'" :state="'default'" @click="saveProduct()" />
                    </div>
                </div>
            </div>
        </div>
        <div class="w-[66%] flex flex-col h-[calc(100vh-100px)] items-center justify-start">
            <div class="flex flex-col items-center w-full mt-4" v-if="product">
                <div class="flex flex-col w-full p-6 justify-start items-center">
                    <ButtonVue class="w-20% mx-2" :label="'Retour'" :height="'large'"
                            @click="redirectToLastPage()" />
                    <div class="w-full my-4">
                        <span class="w-20% mx-2 text-title-3">
                            Dataset de référence
                        </span>
                    </div>
                    <div class="flex flex-row w-full justify-between items-end">
                        <AutocompleteVue class="w-20% mx-2" v-model="product.filters[0].barCode" :height="'large'"
                            :label="'Produit'" :placeholder="'Marque Référence'" :state="'disabled'" />
                        <SelectVue class="w-20% mx-2" v-model="product.filters[0].timePeriod"
                            :options="timePeriodOptions" :placeholder="'Choisir une option...'" :label="'Periode'"
                            :height="'large'" @update:modelValue="async () => {
                                product.filters[1].timePeriod = product.filters[0].timePeriod
                                chartData = false
                                await product.getEvents()
                                chartData = true
                            }" />
                        <DatetimefieldVue class="w-20% mx-2" v-model="product.filters[0]['createdAt[after]']"
                            :placeholder="'Choisir une date...'" :label="'Depuis'" :height="'large'" :top="false"
                            @update:modelValue="async () => {
                                if (product.filters[0].barCode != product.filters[1].barCode) {
                                    product.filters[1]['createdAt[after]'] = product.filters[0]['createdAt[after]']
                                }
                                chartData = false
                                await product.getEvents()
                                chartData = true
                            }" />
                        <DatetimefieldVue class="w-20% mx-2" v-model="product.filters[0]['createdAt[before]']"
                            :placeholder="'Choisir une date...'" :label="'jusqu\'à'" :height="'large'" :top="false"
                            @update:modelValue="async () => {
                                if (product.filters[0].barCode != product.filters[1].barCode) {
                                    product.filters[1]['createdAt[before]'] = product.filters[0]['createdAt[before]']
                                }
                                chartData = false
                                await product.getEvents()
                                chartData = true
                            }" />
                        <ButtonVue class="w-20% mx-2" :label="'Extrait Excel'" :height="'large'"
                            @click="product.getEventsExtract(0)" />
                    </div>
                    <div class="w-full mb-4 mt-8">
                        <span class="w-20% mx-2 text-title-3">
                            Dataset de comparaison
                        </span>
                    </div>
                    <div class="flex flex-row w-full justify-between items-end">
                        <AutocompleteVue class="w-20% mx-2" v-model="brandAndRef" :height="'large'"
                            :label="'Rechercher un produit'" :placeholder="'Marque Référence'" :objects="products"
                            @update:modelValue="getProducts()" @setValue="async (e) => {
                                setProduct(e)
                                product.filters[1].barCode = e.barCode
                                product.filters[1].resource = '/api/products/' + e.barCode
                                product.filters[1]['createdAt[after]'] = product.filters[0]['createdAt[after]']
                                product.filters[1]['createdAt[before]'] = product.filters[0]['createdAt[before]']
                                chartData = false
                                await product.getEvents()
                                chartData = true
                            }" />
                        <SelectVue class="w-20% mx-2" v-model="product.filters[1].timePeriod"
                            :options="timePeriodOptions" :placeholder="'Choisir une option...'" :label="'Periode'"
                            :height="'large'" :state="'disabled'" @update:modelValue="async () => {
                                chartData = false
                                await product.getEvents()
                                chartData = true
                            }" />
                        <DatetimefieldVue class="w-20% mx-2" v-model="product.filters[1]['createdAt[after]']"
                            :placeholder="'Choisir une date...'" :label="'Depuis'" :height="'large'" :top="false"
                            @update:modelValue="async () => {
                                chartData = false
                                await product.getEvents()
                                chartData = true
                            }" />
                        <DatetimefieldVue class="w-20% mx-2" v-model="product.filters[1]['createdAt[before]']"
                            :placeholder="'Choisir une date...'" :label="'jusqu\'à'" :height="'large'" :top="false"
                            @update:modelValue="async () => {
                                chartData = false
                                await product.getEvents()
                                chartData = true
                            }" />
                        <ButtonVue class="w-20% mx-2" :label="'Extrait Excel'" :height="'large'"
                            @click="product.getEventsExtract(1)" />
                    </div>
                    <div
                        class="min-w-[300px] mt-10 w-75% flex flex-row items-center justify-center p-6 bg-white rounded-lg shadow-lg max-h-[50vh]">
                        <Bar v-if="chartData" :data="product.events" />
                        <LoadingVue v-else />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Product from '@/utils/entities/Product'
import FormatDate from '@/utils/FormatDate'
import InputVue from '../components/elements/Input.vue'
import SelectVue from '../components/elements/Select.vue'
import DatetimefieldVue from '../components/elements/Datetimefield.vue'
import ButtonVue from '../components/elements/Button.vue'
import AutocompleteVue from '../components/elements/Autocomplete.vue'
import CheckboxVue from '../components/elements/Checkbox.vue'
import LoadingVue from '../components/elements/Loading.vue'
import WebsocketService from '@/utils/WebsocketService'

import { Bar } from 'vue-chartjs'
import Chart from 'chart.js/auto'

new Chart()

export default ({
    name: 'ProductView',
    components: {
        LoadingVue,
        InputVue,
        ButtonVue,
        Bar,
        SelectVue,
        DatetimefieldVue,
        AutocompleteVue,
        CheckboxVue
    },
    data() {
        return {
            chartData: null,
            timePeriodOptions: [
                { name: "instantané", value: "instant" },
                { name: "30 minutes", value: "halfHour" },
                { name: "1 heure", value: "hour" },
                { name: "6 heures", value: "quarterDay" },
                { name: "1 jour", value: "day" },
            ],
            products: [],

            loaded: false,
            product: null,

            productBrandState: 'default',
            productBrandMessage: null,

            productReferenceState: 'default',
            productReferenceMessage: null,

            productWeightState: 'default',
            productWeightMessage: null,

            productNetWeightState: 'default',
            productNetWeightMessage: null,
            brandAndRef: null,

            minProductState: 'default',
            minProductMessage: null,

            isSensitiveState: 'default',
            isSensitiveMessage: null,
            
            expiringOffsetState: 'default',
            expiringOffsetMessage: null,

            sections: [],

            bodyVisibility: {
                ProductInfoBody: false,
                RefDatasetBody: false,
                CompDatasetBody: false,
            },
            alreadySubscribed: false

            }
        },

        async mounted() {
            WebsocketService.initializeWebSocket(process.env.VUE_APP_WEBSOCKET_PATH);
            this.setupParameters();
            this.brandAndRef = this.$route.params.id
            this.product = new Product({ barCode: this.$route.params.id})

            await this.product.retrieveEntity().then(async () => {
                await this.waitForWebSocketConnection().then(() => {
                    this.product.initializeWebSocketListener();
                });
            })

            this.chartData = true

            this.loaded = true
        },

        beforeUnmount() {
            if (this.product && this.product.barCode) {
                this.product.unsubscribeWebSocket()
            }
        },
        computed: {
            isMobile() {
            return this.$device.isMobile;
            },
            isPortrait() {
            return this.$device.isPortrait;
            },
        },
        methods:{
            async waitForWebSocketConnection() {
                return new Promise((resolve) => {
                const interval = setInterval(() => {
                    if (WebsocketService.isConnected) {
                    clearInterval(interval);
                    resolve();
                    }
                }, 1000); // Check every 1 second
                });
            },
            async setupParameters(){
                await this.$axios
                .get(process.env.VUE_APP_API_BASE_PATH+"/settings/section_names",{withCredentials:true})
                .then((response) => {
                    this.sections = response.data.value[0].value.map((element) => {
                        return {
                            value: element.section,
                            name: element.section,
                        }
                    })
                })
            },
            async saveProduct(){
                let temoin = true;
                if(!this.product.brand){
                    this.productBrandState = 'danger'
                    this.productBrandMessage = 'Champ Requis'
                    temoin = false;
                }
                if(!this.product.reference){
                    this.productReferenceState = 'danger'
                    this.productReferenceMessage = 'Champ Requis'
                    temoin = false;
                }
                if(!this.product.weight){
                    this.productWeightState = 'danger'
                    this.productWeightMessage = 'Champ Requis'
                    temoin = false;
                }
                
                if(temoin){
                    await this.product.updateProduct()
                    this.chartData = false
                    await this.product.getEvents()
                    this.chartData = true
                }
            },

        async saveFile(a) {
            let baseUrl = process.env.VUE_APP_API_BASE_PATH + ''
            baseUrl = baseUrl.substring(0, baseUrl.length - 4)
            let formData = new FormData();
            formData.append('file', a.target.files[0]);
            await this.$axios
                .post(process.env.VUE_APP_API_BASE_PATH + '/media_objects',
                    formData,
                    {
                        withCredentials: true,
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    })
                .then(response => {
                    if (response.status == 201) {
                        this.product.imageUrl = baseUrl + response.data.contentUrl
                    }
                })
        },

        async getProducts() {
            await this.$axios
                .get(process.env.VUE_APP_API_BASE_PATH + '/products?simplesearch=' + this.brandAndRef,
                    {
                        withCredentials: true
                    })
                .then(response => {
                    this.products = response.data['hydra:member']
                    this.products.forEach((element, index) => {
                        this.products[index].description = element.brand + ', ' + element.reference
                    })

                })
        },

        setProduct(event) {
            this.brandAndRef = event.description
            this.products = []
        },

        FormatDate(date) {
            return FormatDate(date)
        },

        toggleBody(bodyId) {
            this.bodyVisibility[bodyId] = !this.bodyVisibility[bodyId];
        },

        redirectToLastPage(){
            window.location.replace(window.localStorage.getItem('lastPath'));
        }

    },
})
</script>