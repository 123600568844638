<template>
    <div>
        <transition name="slide" appear>
            <div class=" w-full md:w-full lg:w-50% xl:w-50% absolute z-20 right-0 lg:right-0 overflow-scroll"
                :class="!isMobile() ? 'top-[100px] max-h-[calc(100vh-100px)]' : 'top-[70px] max-h-[calc(100vh-70px)]'">
                <div class="flex flex-row py-1 w-50% fixed bg-white items-center justify-center z-40">
                    <ButtonVue v-if="this.filter!==''" class="mx-1" @click="setFilter('')" :filling="'outlined'" :label="null" :height="'medium'" :icon="'XMarkIcon'" :state="'danger'" />
                    <ButtonVue class="mx-1 flex-1"
                        :class="{'opacity-50': this.filter !== '' && this.filter !== 'danger' }"
                        @click="setFilter('danger')" :filling="'filled'" :label="null" :icon="'XCircleIcon'"
                        :height="'medium'" :state="'danger'" />
                    <ButtonVue class="mx-1 flex-1"
                        :class="{'opacity-50': this.filter !== '' && this.filter !== 'warning' }"
                        @click="setFilter('warning')" :filling="'filled'" :label="null" :icon="'ExclamationTriangleIcon'"
                        :height="'medium'" :state="'warning'" />
                    <ButtonVue class="mx-1 flex-1"
                        :class="{'opacity-50': this.filter !== '' && this.filter !== 'default' }"
                        @click="null" :filling="'filled'" :label="null" :icon="'QuestionMarkCircleIcon'" :height="'medium'"
                        :state="'success'" />
                    <ButtonVue class="mx-1 flex-1"
                        :class="{'opacity-50': this.filter !== '' && this.filter !== 'neutral' }"
                        @click="null" :filling="'filled'" :label="null" :icon="'Cog6ToothIcon'" :height="'medium'"
                        :state="'neutral'" />
                </div>
                <ul class="flex flex-col items-end bg-gray-50 shadow-xl md:rounded-b-lg relative top-[49px]">
                    <li v-for="(value, index) in notifications" :key="index" class="min-w-[630px] w-full"
                        @click="redirect(value.resourceUri)">
                        <router-link :to="getLink(value.resourceUri)">
                            <div v-if="!value.seenAt"
                                class="h-full flex flex-row items-center justify-start p-3 rounded-[20px] m-1"
                                :class="getTypeColor(value.type, 'bg')">
                                <component :is="getTypeIcon(value.type)" fill="currentColor"
                                    class="mr-1 h-6 w-6 text-gray-50" />
                                <div class="flex flex-col justify-start items-start w-85% ml-2">
                                    <span class="text-gray-50 font-bold"> {{ getTitle(value.type) }}</span>
                                    <span class="text-sm text-gray-100 pt-1">{{ value.message }}</span>
                                    <span class="text-xs text-gray-100 flex justify-end w-full pt-2 relative left-3">{{ new
                                        Date(value.createdAt).toLocaleDateString() }}
                                        {{ new Date(value.createdAt).toLocaleTimeString([], {
                                            hour: '2-digit', minute:
                                                '2-digit'
                                        }) }}</span>
                                </div>
                            </div>
                        </router-link>
                    </li>
                </ul>
                <div v-if="!firstLoad" @click="loadMore()"
                    class=" text-center underline text-primary-500 p-1.5 pb-3.5 font-semibold relative top-[49px]">
                    Load More
                </div>
            </div>
        </transition>
    </div>
</template>

<script>

import axios from 'axios'
import * as heroIcons from '@heroicons/vue/24/outline'
import PaginatorVue from '../components/elements/Paginator.vue'
import ButtonVue from '../components/elements/Button.vue'
import WebsocketService from '@/utils/WebsocketService';

export default ({
    name: 'NotificationCenter',
    components: {
        PaginatorVue,
        ButtonVue,
    },
    data() {
        return {
            heroIcons: heroIcons,
            loaded: false,
            firstLoad: true,
            toasts: [],
            notifications: [],
            pageNumber: 1,
            pageTotal: 100, // comment recuperer le vrai max de pages?
            filter: '',
        }
    },
    
    async mounted() {
        await this.getAlerts()

        WebsocketService.initializeWebSocket(process.env.VUE_APP_WEBSOCKET_PATH);

        WebsocketService.addListener((type, data) => {
        if (type === 'message') {
            this.handleWebSocketMessage(data);
        }
        });

        WebsocketService.addListener((type) => {
        if (type === 'open') {
            WebsocketService.sendMessage({
                command: 'subscribe',
                resource: '/api/alerts',
            });
        }
        });

    },
    methods: {
        handleWebSocketMessage(data) {
            if (["update", "create", "delete"].includes(data.command) && data.resource.includes('/api/alerts')) {
                this.getAlerts();
            }
        },

        async getAlerts() {
            let url = process.env.VUE_APP_API_BASE_PATH + '/alerts?page=' + this.pageNumber;

            if (this.filter.length > 0) {
                url += '&type=' + this.filter;
            }

            await axios
                .get(url, { withCredentials: true })
                .then((response) => {
                    this.notifications = [...this.notifications, ...response.data['hydra:member']];
                });

            if (!this.firstLoad) {
                this.addToast(this.notifications[0]);
            }

            this.firstLoad = false;
        },

        async loadMore() {
            this.pageNumber++
            this.getAlerts()
        },

        setFilter(type) {
            if (this.filter == type) {
                this.filter = '';
            } else {
                this.filter = type;
            }

            this.notifications = [];
            this.getAlerts();
        },

        redirect(resourceUri) {
            var resourceArray = resourceUri.split('/')
            switch (resourceArray[2]) {
                case 'linears':
                    window.location.replace('/shelf/' + resourceArray[3])
                    break;
            }
        },

        getTypeColor(type, text) {
            switch (type) {
                case "warning":
                    return text + "-warning-500"
                case "danger":
                    return text + "-danger-500"
                case "success":
                    return text + "-success-500"

            }
        },

        getTypeIcon(type) {
            switch (type) {
                case "warning":
                    return this.heroIcons['ExclamationTriangleIcon']
                case "danger":
                    return this.heroIcons['XCircleIcon']
                case "success":
                    return this.heroIcons['CheckCircleIcon']

            }
        },

        getTitle(type) {
            switch (type) {
                case "warning":
                    return "RUPTURE PARTIELLE"
                case "danger":
                    return "RUPTURE TOTALE"
                case "success":
                    return "TITRE A"
            }
        },

        getLink(value) {
            if (value) {
                switch (value.split('/')[2]) {
                    case 'linears':
                        return '/shelf/' + value.split('/')[3]

                    default:
                        return '/dashboard'
                }
            }
        },

        addToast(notif) {
            this.toasts.push(notif)
            setTimeout(() => {
                this.toasts.shift()
            }, 10000)
        },

        // async logout() {
        //     await this.$axios
        //         .post(process.env.VUE_APP_API_BASE_PATH + '/logout',
        //             {}, { withCredentials: true })
        //         .then(response => {
        //             if (response.status == 200) {
        //                 window.location.replace('/login');
        //             }
        //         })
        //         .catch(() => {
        //         });
        // },
    },
})
</script>

<style scoped>
.slideleft-enter-from,
.slideleft-leave-to {
    transform: translate(100%, 0);
}

.slideleft-enter-active,
.slideleft-leave-active {
    transition: all .3s ease-in-out;
}

.slidedown-enter-from,
.slidedown-leave-to {
    min-height: 100%;
}

.slidedown-enter-active,
.slidedown-leave-active {
    transition: all .3s ease-in-out;
}


.fade-enter-active,
.fade-leave-active {
    transition: all .3s linear;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.overlay {
    background: #9ca3af;
    opacity: 0.5;
}</style>