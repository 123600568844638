<template lang="html">
    <div class="flex flex-col justify-start align-top select-none">
        <span v-if="label" class="mb-1 mx-1 text-neutral-700" 
        :class="styling(styles.span_label)">
            {{label}}
        </span>
            <div class="relative w-full">
                <div @click="open = !open">
                    <input
                    class="rounded-md box-border border transition-all w-full outline-none focus:ring-1 focus:ring-inset focus:ring-offset-1 focus:border-primary-400 focus:ring-primary-400 shadow-sm hover:shadow-lg focus:shadow-xs"
                    type="text"
                    v-model="dataValue"
                    :class="styling(styles.text)"
                    :placeholder="placeholder"
                    disabled
                    />
                    <component :is="heroIcons['CalendarDaysIcon']" 
                    :class="styling(styles.icon_calendar)" 
                    class="absolute stroke-1 stroke-neutral-600" 
                    />
                </div>
                <div v-if="open" class="absolute right-0 z-30 shadow-xl bg-gray-50 rounded-lg p-2"
                :class="top ? 'bottom-[98%]' : 'top-[98%]'">
                    <div class="flex flex-col justify-center">
                        <div class="flex flex-row justify-center w-full p-2">
                            <ul class="inline-flex items-center -space-x-px w-full">
                                <li class="border border-gray-400 rounded-l-sm p-1 text-gray-400 hover:bg-gray-300 hover:text-white hover:border-gray-300" @click="previousMonth()">
                                    <component :is="heroIcons['ChevronLeftIcon']" fill="none" class="stroke-1 h-7 w-7" />
                                </li>
                                <li class="w-full border border-gray-400 p-1 text-body-2 text-gray-400 hover:bg-gray-300 hover:text-white hover:border-gray-300">
                                    <div class="h-7 w-full px-1 flex items-center justify-center">{{ getMonthName().substr(0,4).toUpperCase() }} - {{ getYear() }}</div>
                                </li>
                                <li class="border border-gray-400 rounded-r-sm p-1 text-gray-400 hover:bg-gray-300 hover:text-white hover:border-gray-300" @click="nextMonth()">
                                    <component :is="heroIcons['ChevronRightIcon']" fill="none" class="stroke-1 h-7 w-7" />
                                </li>
                            </ul>
                        </div>
                        <div class="flex flex-row justify-between items-center w-full p-2">
                            <SelectVue 
                            class="w-50% mr-2"
                            :options="optionsHours"
                            :placeholder="'Heures'" 
                            :height="'medium'"
                            v-model="hours" 
                            @update:modelValue="update()"
                            />
                            <span>
                                :
                            </span>
                            <SelectVue 
                            class="w-50% ml-2"
                            :options="optionsMinutes"
                            :placeholder="'Minutes'" 
                            :height="'medium'"
                            v-model="minutes" 
                            @update:modelValue="update()"
                            />
                        </div>
                        <table class="w-full">
                            <thead>
                                <tr>
                                    <th class="text-body-5 text-gray-400 text-center">LUN</th>
                                    <th class="text-body-5 text-gray-400 text-center">MAR</th>
                                    <th class="text-body-5 text-gray-400 text-center">MER</th>
                                    <th class="text-body-5 text-gray-400 text-center">JEU</th>
                                    <th class="text-body-5 text-gray-400 text-center">VEN</th>
                                    <th class="text-body-5 text-gray-400 text-center">SAM</th>
                                    <th class="text-body-5 text-gray-400 text-center">DIM</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(week,index) in getWeeks()" :key="index">
                                    <td v-for="day in week" :key="day.date"
                                    class="text-center w-10 !m-0"
                                    @click="selectDate(day.fullDate)">
                                        <div class="rounded-full p-2 w-10 hover:bg-gray-200 hover:text-gray-900 flex justify-center items-center"
                                        :class="getDateClass(day)">
                                            {{ day.date }}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
            <span v-if="message" 
            class="mt-1 mx-1 inline-flex items-center" 
            :class="styling(styles.span_message)">
                <component
                :is="heroIcons[styles.icon_message[state]]" 
                fill="currentColor" 
                class="mr-1" 
                :class="styling(styles.icon_message)"/>
                {{message}}
            </span>
            <span v-if="helper" 
            class="mt-1 mx-1 text-neutral-400" 
            :class="styling(styles.span_helper)">
                {{helper}}
            </span>
        </div>
  </template>

<script>

import * as heroIcons from '@heroicons/vue/24/outline'

import SelectVue from './Select.vue'
import FormatDate from "@/utils/FormatDate"
  
export default  {
  name: 'DatetimefieldVue',
  components:{
    SelectVue
  },
  props: {
    modelValue: {
        type: Date,
    },
    height: {
        type: String,
        validator(value) {
            return ['large', 'medium', 'small'].includes(value)
        }, 
        default: 'medium'
    },
    placeholder: {
        type: String,
        default: 'Insert text here'
    },
    label: {
        type: String,
        default: ''
    },
    message: {
        type: String,
        default: ''
    },
    helper: {
        type: String,
        default: ''
    },
    state: {
        type: String,
        validator(value) {
            return ['default', 'success', 'danger', 'warning', 'disabled'].includes(value)
        }, 
        default: 'default'
    },
    top: {
        type: Boolean,
        default: false
    }
  },
  watch: {
    modelValue: {
        handler() {
            this.dataValue = FormatDate(this.modelValue, 'datetime')
            this.hours = this.modelValue.getHours()
            this.minutes = this.modelValue.getMinutes()
        },
        deep: true
    },
    height: {
      handler() {},
      deep: true
    },
    placeholder: {
      handler() {},
      deep: true
    },
    label: {
      handler() {},
      deep: true
    },
    message: {
      handler() {},
      deep: true
    },
    helper: {
      handler() {},
      deep: true
    },
    state: {
      handler() {},
      deep: true
    },
    top: {
      handler() {},
      deep: true
    },
  },
  data () {
    return {
      heroIcons: heroIcons,
      dataValue: '',
      hours: 0,
      optionsHours:[
        {value: 0, name: "00"},
        {value: 1, name: "01"},
        {value: 2, name: "02"},
        {value: 3, name: "03"},
        {value: 4, name: "04"},
        {value: 5, name: "05"},
        {value: 6, name: "06"},
        {value: 7, name: "07"},
        {value: 8, name: "08"},
        {value: 9, name: "09"},
        {value: 10, name: "10"},
        {value: 11, name: "11"},
        {value: 12, name: "12"},
        {value: 13, name: "13"},
        {value: 14, name: "14"},
        {value: 15, name: "15"},
        {value: 16, name: "16"},
        {value: 17, name: "17"},
        {value: 18, name: "18"},
        {value: 19, name: "19"},
        {value: 20, name: "20"},
        {value: 21, name: "21"},
        {value: 22, name: "22"},
        {value: 23, name: "23"},
      ],
      minutes: 0,
      optionsMinutes:[
        {value: 0, name: "00"},
        {value: 1, name: "15"},
        {value: 2, name: "30"},
        {value: 3, name: "45"},
      ],
      date: null,
      day: null,
      open: false,
      styles: {
        'span_label' : {
            'height' : {
                'large': 'text-body-5',
                'medium': 'text-body-6',
                'small': 'text-body-7',
            },
        },
        'text' : {
            'height' : {
                'large': 'h-text-input-large text-body-2 pl-[0.5rem]',  
                'medium': 'h-text-input-medium text-body-5 pl-[0.75rem]', 
                'small': 'h-text-input-small text-body-6 pl-[1rem]',
            },
            'state' : {
                'default': 'border-neutral-500 hover:border-neutral-300',
                'success': 'border-success-500 hover:border-success-300',
                'danger': 'border-danger-500 hover:border-danger-300',
                'warning': 'border-warning-500 hover:border-warning-300',
                'disabled': 'border-neutral-500 bg-neutral-400/10',
            }
        },
        'span_message' : {
            'height' : {
                'large': 'text-body-5 text-neutral-600',
                'medium': 'text-body-6 text-neutral-600',
                'small': 'text-body-7 text-neutral-600',
            },
            'state' : {
                'default': 'text-neutral-600',
                'success': 'text-success-600',
                'danger': 'text-danger-600',
                'warning': 'text-warning-600',
                'disabled': 'text-neutral-600',
            }
        },
        'icon_message' : {
            'height' : {
                'large': 'h-5 w-5',
                'medium': 'h-3 w-3',
                'small': '',
            },
            'state' : {
                'default': '',
                'success': 'CheckCircleIcon',
                'danger': 'XCircleIcon',
                'warning': 'ExclamationIcon',
                'disabled': '',
            }
        },
        'span_helper' : {
            'height' : {
                'large': 'text-body-5 text-neutral-600',
                'medium': 'text-body-6 text-neutral-600',
                'small': 'text-body-7 text-neutral-600',
            },
        },
        'icon_calendar' : {
            'height' : {
                'large': 'h-6 w-6 right-[0.75rem] inset-y-[0.75rem]',
                'medium': 'h-5 w-5 right-[0.65rem] inset-y-[0.65rem]',
                'small': 'h-4 w-4 right-[0.5rem] inset-y-[0.5rem]',
            },
        }
      }
    }
  },
  created(){
    this.date = new Date();
    this.day = FormatDate(this.date, 'datetime')
    this.dataValue = FormatDate(this.modelValue, 'datetime')
  },
  methods: {
    log(a){console.log(a)},
    getMonthName() {
        return this.date.toLocaleString('fr-FR', { month: 'long' });
    },

    previousMonth(){
        this.date = new Date(this.date.setMonth(this.date.getMonth()-1));
    },

    nextMonth(){
        this.date = new Date(this.date.setMonth(this.date.getMonth()+1));
    },

    getYear(){
        return this.date.getFullYear()
    },

    getWeeks(){
        var weeks = []
        var today =  new Date()
        var firstDay = new Date(this.date.setDate(1))
        var d = new Date(this.date.setDate(1))
        if(firstDay.getDay() !== 1){
            do {
                d.setDate(d.getDate() - 1);
            } while (d.getDay() !== 1);
        }
        do {
            var week = []
            do {
                week.push({
                    date: d.getDate(),
                    sameMonth:  d.getMonth() == firstDay.getMonth(),
                    today: FormatDate(d, 'date') == FormatDate(today, 'date'),
                    fullDate: FormatDate(d, 'date')
                })
                d.setDate(d.getDate() + 1);
            } while (d.getDay() !== 1)
            weeks.push(week)
        } while (d.getMonth() == firstDay.getMonth())
        return weeks
    },

    selectDate(day){
        this.open = false
        this.day = day
        this.update()
    },

    update(){
        const hours = this.optionsHours.find((element) => element.value === this.hours).name
        const minutes = this.optionsMinutes.find((element) => element.value === this.minutes).name
        this.dataValue = this.day + " " + hours + ":" + minutes
        this.$emit('update:modelValue', new Date(this.day.split('/')[2], this.day.split('/')[1] -1 , this.day.split('/')[0], hours, minutes))
    },

    getDateClass(day){
        let dateString = day.fullDate + " " + 
        this.optionsHours.find((element) => element.value === this.hours).name + ":" +
        this.optionsMinutes.find((element) => element.value === this.minutes).name
        if(this.dataValue == dateString){
            return "text-body-5 text-white bg-primary-500"
        } else if(!day.sameMonth && !day.today){
            return "text-body-5 text-gray-400"
        } else if(!day.sameMonth && day.today){
            return "text-body-5 text-white"
        } else if(day.sameMonth && !day.today){
            return "text-body-4 text-gray-700"
        } else if(day.sameMonth && day.today){
            return "text-body-5 text-white bg-neutral-300"
        }
    },
    
    getState(object){
        switch (this.state){
            case 'default':{
                return object.default
            }
            case 'success':{
                return object.success
            }
            case 'danger':{
                return object.danger
            }
            case 'warning':{
                return object.warning
            }
            case 'disabled':{
                return object.disabled
            }
        }

    },
    getHeight(object){
        switch (this.height){
            case 'large':{
                return object.large
            }
            case 'medium':{
                return object.medium
            }
            case 'small':{
                return object.small
            }
        }
    },
    styling(object){
        var valren = ''
        object.height ? valren += this.getHeight(object.height) +' ' : null
        object.state ? valren += this.getState(object.state) +' ' : null
        return valren
    }
  },
}


</script>
  
  