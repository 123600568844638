import axios from 'axios';
//import WebsocketService from '../WebsocketService';

export default class Setting {
  constructor(object) {
    this.build(object)
  }

  initializeWebSocketListener() {

    //WebsocketService.initializeWebSocket(process.env.VUE_APP_WEBSOCKET_PATH);

    //WebsocketService.sendMessage({
    //    command: 'subscribe',
    //    resource: `/api/settings/${this.identifier}`,
    //});

    //WebsocketService.addListener(async (type, data) => {
    //  if (type === 'message' && data.resource === `/api/settings/${this.identifier}`) {
    //    await this.retrieveEntity();
    //  }
    //});
  }

  unsubscribeWebSocket() {
    //WebsocketService.sendMessage({
    //  command: 'unsubscribe',
    //  resource: `/api/settings/${this.identifier}`,
    //});
  }

  async retrieveEntity() {
    if (this.identifier) {
      await axios
        .get(`${process.env.VUE_APP_API_BASE_PATH}/settings/${this.identifier}`, {
          withCredentials: true,
        })
        .then(response => {
          if (response.status === 200) {
            this.build(response.data);
          }
        });
    }
  }

  build(object) {
    this.id = object.id || null;
    this.type = object.type || null;
    this.scope = object.scope || null;
    this.resources = object.resources || [];
    this.value = object.value || [];
    this.updatedBy = object.updatedBy || null;
    this.updatedAt = object.updatedAt ? new Date(object.updatedAt) : null;
    this.identifier = object.identifier || null;
    this.display = object.display || [];
  }

  async create() {
    const data = {
      type: this.type,
      scope: this.scope,
      resources: this.resources,
      value: this.value,
      identifier: this.identifier,
      display: this.display,
    };

    await axios
      .post(`${process.env.VUE_APP_API_BASE_PATH}/settings`, data, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json' },
      })
      .then(response => {
        this.build(response.data);
      });
  }

  async update() {
    const data = {
      type: this.type,
      scope: this.scope,
      resources: this.resources,
      value: this.value,
      identifier: this.identifier,
      display: this.display,
    };

    await axios
      .put(`${process.env.VUE_APP_API_BASE_PATH}/settings/${this.identifier}`, data, {
        withCredentials: true,
        headers: { 'Content-Type': 'application/json' },
      })
      .then(response => {
        this.build(response.data);
      });
  }

  async delete() {
    await axios
      .delete(`${process.env.VUE_APP_API_BASE_PATH}/settings/${this.identifier}`, {
        withCredentials: true,
      })
      .then(() => {
        console.log(`Setting with identifier ${this.identifier} deleted successfully.`);
      });
  }
}
