<template class="mx-auto relative">
  <LoadingVue v-if="!loaded" class="relative" :class="isMobile ? 'top-[71px]' : 'top-[100px]'" />

  <div v-else id="Container" :key="reloadCount" ref="container" class="flex justify-around w-full h-full fixed"
    :class="isMobile ? '' : 'top-[100px]'">
    <div id="Map" class="flex flex-col">

      <div class="absolute w-full top-[65px] right-[10px]">
        <ButtonVue v-if="isMobile" :label="'Legende'" :height="'small'" :state="'neutral'"
          @click="modalLegendOpen = true" class="w-[100px] absolute right-0 mr-2 !bg-gray-100 !text-neutral-500" />
      </div>

      <!-- Grid -->
      <div id="Grid" ref="grid"
        class="px-2 justify-center rounded-3xl relative cursor-pointer w-fit touch-none overscroll-contain"
        :class="isMobile ? 'top-[110px]' : 'top-[81px]'" :style="{ width: 'fit-content' }">
        <div
          class="grid grid-cols-40 border-gray-300 w-fit max-w-[100vw] max-h-[80vh] overflow-x-auto overflow-y-auto overscroll-contain touch-none touch-pan-x touch-pan-y"
          ref="grid">
          <div v-for="(row, rowIndex) in lengthStore" :key="`${rowIndex}`" class="flex overscroll-contain"
            :class="rowIndex === 0 ? 'border-t' : ''">
            <div v-for="(column, columnIndex) in widthStore" class="border-l border-b border-gray-300 relative"
              :class="columnIndex === (widthStore - 1) ? 'border-r' : ''" :id="`${rowIndex}_${columnIndex}`"
              :key="`${rowIndex}_${columnIndex}`" :ref="`${rowIndex}_${columnIndex}`"
              :style="{ height: squareSize + 'px', width: squareSize + 'px', backgroundColor: '', border: '' }"
              @click="clickedSquare(columnIndex, rowIndex)" @mouseover="handleMouseOver($event, columnIndex, rowIndex)"
              @mouseout="handleMouseOut">
              <span class="absolute flex h-5 w-5 top-[50%] right-[50%] translate-x-1/2 -translate-y-1/2 z-10"
                v-if="hasAlert(columnIndex, rowIndex)">
                <span class="animate-ping absolute inline-flex h-full w-full rounded-full opacity-75"
                  :class="alertType(columnIndex, rowIndex)"></span>
                <span class="relative inline-flex rounded-full h-5 w-5"
                  :class="alertType(columnIndex, rowIndex)"></span>
              </span>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- legend desktop-->
    <div v-if="!isMobile" id="Legend" class="flex flex-col relative top-[15px] items-center">
      <div class="py-2">
        <ButtonVue :label="'Modifier'" :height="'large'" :state="'default'" @click="goToModif()" class="w-[150px]" />
      </div>
      <div class="flex flex-col flex-shrink border-[1px] border-gray-500 mx-2 rounded-3xl bg-gray-50"
        :style="{ 'max-height': `${lengthStore * squareSize}px` }">
        <div class="border-b-[1px] border-gray-500 w-full flex justify-center">
          <ButtonVue class="mx-1 !bg-transparent !shadow-none !text-gray-900" :filling="'text'" :label="'Legende'"
            :icon="null" :height="'large'" :state="'neutral'" />
        </div>
        <div class="pt-2 max-h-100% overflow-y-auto h-100%">
          <div v-for="categorie in categories" :key="categorie.id" class="flex flex-row items-center my-2"
            :class="{ 'ml-8': screenWidth >= 1400, 'ml-4': screenWidth < 1400 }">
            <div class="min-w-[20px] min-h-[20px] border border-gray-700 drop-shadow-md mr-2 cursor-pointer"
              :style="{ backgroundColor: categorie.fill }">
            </div>
            <ButtonVue
              class="mx-1 !bg-transparent !shadow-none !text-left !text-gray-900 !font-normal cursor-auto hover:opacity-100 hover:shadow-none"
              :filling="'text'" :label="categorie.section" :icon="null" :height="'large'" :state="'neutral'" />
          </div>
        </div>
      </div>
    </div>

    <!-- legend mobile-->
    <RightSheet v-else v-show="modalLegendOpen" @closeModal="modalLegendOpen = false" class="z-50">
      <template v-slot:body>
        <div id="LegendMobile" class="flex flex-col relative items-center overflow-y-auto z-50 max-h-[12] w-100%">
          <div class="p-2 w-full"></div>
          <div class="flex flex-col flex-shrink mx-2 rounded-3xl bg-gray-50 pb-8"
            :style="{ 'max-height': `90vh`, 'width': '100%' }">
            <div class="border-b-[1px] border-gray-500 flex justify-center mx-6">
              <ButtonVue class="mx-1 !bg-transparent !shadow-none !text-gray-900" :filling="'text'" :label="'Legende'"
                :icon="null" :height="'large'" :state="'neutral'" />
            </div>
            <div class="pt-2 max-h-100% overflow-y-auto h-100% w-100% px-6">
              <div v-for="categorie in categories" :key="categorie.id" class="flex flex-row items-center my-2"
                :class="{ 'ml-8': screenWidth >= 1400, 'ml-4': screenWidth < 1400 }">
                <div class="min-w-[20px] min-h-[20px] border border-gray-700 drop-shadow-md mr-2 cursor-pointer"
                  :style="{ backgroundColor: categorie.fill }">
                </div>
                <ButtonVue
                  class="mx-1 !bg-transparent !shadow-none !text-left !text-gray-900 !font-normal cursor-auto hover:opacity-100 hover:shadow-none"
                  :filling="'text'" :label="categorie.section" :icon="null" :height="'large'" :state="'neutral'" />
              </div>
            </div>
          </div>
        </div>
      </template>
    </RightSheet>

    <RightSheet v-show="modalShelfOpen" @closeModal="modalShelfOpen = false" :width="85">
      <template v-slot:body>
        <ShelfViewer v-if="modalShelfOpen" :aisle="grid[x]?.[y]?.aisle" :position="grid[x]?.[y]?.row" />
      </template>
    </RightSheet>

    <div v-if="showCategory" ref="pointer" class="fixed z-10 rounded-md py-1 px-2 text-xs" :style="{
      top: pointerPosition.x + 'px',
      left: pointerPosition.y + 'px',
      backgroundColor: hoveredColor,
      color: textColor}">
      {{ hoveredCategory }}
    </div>
  </div>
</template>

<script>
import ButtonVue from '@/components/elements/Button.vue';
import RightSheet from '@/components/containers/RightSheet.vue';
import API from '@/utils/MapUtils/API.js';
import ShelfViewer from '../components/map/ShelfViewer.vue';
import LoadingVue from '../components/elements/Loading.vue';
import * as heroIcons from '@heroicons/vue/24/outline';
import { nextTick } from 'vue';

export default {
  components: {
    ButtonVue,
    RightSheet,
    ShelfViewer,
    LoadingVue,
  },
  data() {
    return {
      heroIcons: heroIcons,
      screenHeight: window.innerHeight,
      screenWidth: window.innerWidth,
      API: null,
      widthStore: 0,
      lengthStore: 0,
      squareSize: 25,
      minSize: 0,
      maxSize: 60,
      grid: [],
      squares: [],
      categories: [],
      sections: [],
      showCategory: false,
      hoveredCategory: null,
      hoveredColor: 'white',
      textColor: 'black',
      pointerPosition: { x: 0, y: 0 },
      x: 0,
      y: 0,
      modalShelfOpen: false,
      modalLegendOpen: false,
      loaded: false,
      reloadCount: 0,
      mapAlerts: [],
      PINCH_IN: "red",
      PINCH_OUT: "green",
      events: [],
      gap: -1,
    };
  },
  computed: {
    isMobile() {
      return this.$device.isMobile;
    },
    isPortrait() {
      return this.$device.isPortrait;
    },
  },
  async created() {
    this.API = new API();
    await this.loadData();
    this.calculateSquareSize();
  },
  beforeUnmount() {
    this.removeListeners();
  },
  updated(){
    this.fillMap()
  },
  methods: {
    /**
    * @description:
    * @param {*} x: coordonnee x (colonne) du carre clique
    * @param {*} y: coordonnee y (ligne) du carre clique
    */
    clickedSquare(x, y) {
      if (this.grid[y][x] !== null) {
        this.y = x;
        this.x = y;
        if (this.grid[y][x] != null) {
          if (this.grid[y][x].row != null) {
            this.modalShelfOpen = true;
          }
        }
      }
    },

    async loadData() {
      try {
        const load = await this.API.getMap();
        this.widthStore = load.size.widthStore;
        this.lengthStore = load.size.lengthStore;
        this.grid = Array.from({ length: this.widthStore }, () => Array.from({ length: this.lengthStore }, () => null));
        load.squares.forEach(square => {
          this.grid[square.y][square.x] = square;
        });
      } catch (error) {
        console.error('Error occurred while loading map data:', error);
        this.grid = [];
      }

      try {
        const loadCategories = await this.API.getCategories();
        this.categories = loadCategories;
        this.sections = this.categories.map((cat) => ({ name: cat.section, value: cat.id }));
      } catch (error) {
        console.error('Error occurred while loading categories:', error);
        this.categories = [];
      }

      this.fillMap()

      try {
        const loadMapAlerts = await this.API.getMapAlerts();
        this.mapAlerts= loadMapAlerts;
      } catch (error) {
        console.error('Error occurred while loading map alerts:', error);
        this.mapAlerts = [];
      }

      this.loaded = true;
    },
    fillMap() {
      // Backup the filled squares from the grid
      let squaresBackup = this.grid.flatMap((innerArray, rowIndex) =>
        innerArray
          .map((value, columnIndex) =>
            value !== null ? { key: `${rowIndex}_${columnIndex}`, fill: value.fill } : null
          )
          .filter(cell => cell !== null)
      );

      // Use nextTick to ensure the DOM is updated before accessing refs
      nextTick(() => {
        // Set background color for each square based on the backup
        squaresBackup.forEach(square => {
          const refElement = this.$refs[`${square.key}`];
          if (refElement && refElement[0]) {  // Check if ref exists
            refElement[0].style.backgroundColor = square.fill;
          }
        });
      });
    },
    calculateSquareSize() {
      const isSmallScreen = this.screenWidth < 1400;
      const horizontalSize = (isSmallScreen ? this.screenWidth - 200 : this.screenWidth - 300) / this.widthStore - 1;
      const verticalSize = (this.screenHeight - (this.isMobile ? 110 : 200)) / this.lengthStore - 1;
      this.squareSize = Math.min(horizontalSize, verticalSize);
      this.minSize = this.squareSize;
    },
    accessElement() {
      this.$nextTick(() => {
        const element = this.$refs.grid;
        if (element) {
          element.addEventListener('pointerdown', this.pointerDown);
          element.addEventListener('pointermove', this.pointerMove);
          element.addEventListener('pointerup', this.pointerUp);
          element.addEventListener('pointercancel', this.pointerUp);
          element.addEventListener('pointerout', this.pointerUp);
          element.addEventListener('pointerleave', this.pointerUp);
        }
      });
    },
    removeListeners() {
      const element = this.$refs.grid;
      if (element) {
        element.removeEventListener('pointerdown', this.pointerDown);
        element.removeEventListener('pointermove', this.pointerMove);
        element.removeEventListener('pointerup', this.pointerUp);
        element.removeEventListener('pointercancel', this.pointerUp);
        element.removeEventListener('pointerout', this.pointerUp);
        element.removeEventListener('pointerleave', this.pointerUp);
      }
    },
    pointerDown(event) {
      this.events.push(event);
    },
    pointerMove(event) {
      const index = this.events.findIndex(e => e.pointerId === event.pointerId);
      if (index !== -1) this.events[index] = event;
      if (this.events.length === 2) {
        const d = this.distance2D(this.events[0].clientX, this.events[0].clientY, this.events[1].clientX, this.events[1].clientY);
        if (this.gap > 0) {
          const newSize = d < this.gap ? this.squareSize - 0.01 * this.gap : this.squareSize + 0.01 * this.gap;
          this.squareSize = Math.max(this.minSize, Math.min(this.maxSize, newSize));
        }
        this.gap = d;
      }
    },
    pointerUp(event) {
      this.events = this.events.filter(e => e.pointerId !== event.pointerId);
      if (this.events.length < 2) this.gap = -1;
    },
    distance2D(x1, y1, x2, y2) {
      return Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2);
    },
    handleMouseOver(event, x, y) {
      if (this.grid[y][x]) {
        const rect = event.target.getBoundingClientRect();
        this.pointerPosition = {
          y: rect.left + window.scrollX + rect.width + 5,
          x: rect.top + window.scrollY,
        };
        this.showCategory = true;
        this.hoveredCategory = this.grid[y][x].section;
        this.hoveredColor = this.grid[y][x].fill;
        this.textColor = getContrast(this.hoveredColor);
      }
    },
    handleMouseOut() {
      this.showCategory = false;
      this.hoveredCategory = null;
    },
    hasAlert(x, y) {
      return this.mapAlerts.some(item =>
        parseInt(item.aisle) === this.grid[y][x]?.aisle && parseInt(item.position) === this.grid[y][x]?.row
      );
    },
    alertType(x, y) {
      return this.hasAlert(x, y) ? 'bg-danger-500' : 'bg-none';
    },
    goToModif() {
      this.$router.push('/mapEditor');
    },
  },
  watch: {
    loaded(newVal) {
      if (newVal) this.accessElement();
    },
  },
};

function getContrast(hexColor) {
  hexColor = hexColor.replace('#', '');
  const r = parseInt(hexColor.substr(0, 2), 16);
  const g = parseInt(hexColor.substr(2, 2), 16);
  const b = parseInt(hexColor.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128 ? 'black' : 'white';
}
</script>
