export default (dateString, format = 'datetime') => {
    var date = new Date(dateString)
    var year = date.toLocaleString("default", { year: "numeric" });
    var month = date.toLocaleString("default", { month: "2-digit" });
    var day = date.toLocaleString("default", { day: "2-digit" });
    var hour = date.toLocaleTimeString('fr-FR').split(':')[0];
    var minute = date.toLocaleTimeString('fr-FR').split(':')[1];
    switch(format){
        case 'date': {
            return  day + "/" + month + "/" + year;
        }
        case 'time': {
            return hour+":"+minute;
        }
        case 'datetime': {
            return  day + "/" + month + "/" + year+ " "+ hour+":"+minute;
        }
        default: {
            return  day + "/" + month + "/" + year + " "+ hour+":"+minute;
        }
    }
}