<template>
    <div class="flex flex-col items-start w-full">
        <div class="flex flex-col w-full">
            <div class="w-full">
                <table class="table-auto w-full">
                    <thead>
                        <tr class="!h-text-input-medium" :class="animateClass">
                            <td class="px-1 w-full align-bottom pb-4">
                                <InputVue :placeholder="placeholder" :label="label" v-model="currentOption.section"
                                    :width="'full'" :height="'medium'" :state="stateInput" />
                            </td>
                            <td class="px-1 align-bottom pb-4">
                                <div class="relative">
                                    <button
                                        class=" rounded-md transition-all box-border flex justify-around items-center shadow-sm hover:shadow-lg active:shadow-xs bg-white px-2 py-2 border border-gray-300">
                                        <span :style="{ backgroundColor: currentOption.fill }"
                                            class="w-6 h-6 inline-block rounded-full border border-gray-300"></span>
                                    </button>
                                    <input type="color" v-model="currentOption.fill"
                                        class="absolute top-0 left-0 opacity-0 w-full h-full cursor-pointer" />
                                </div>
                            </td>
                            <td class="px-1 align-bottom pb-4">
                                <ButtonVue @click="addRow" :filling="'outlined'" :label="null" :height="'medium'"
                                    :icon="'PlusIcon'" :state="statePlusButton" />
                            </td>
                        </tr>
                    </thead>
                </table>
            </div>
            <div class="w-full overflow-y-scroll" style="max-height: calc(100vh - 310px);">
                <table class="table-auto w-full">
                    <tbody>
                        <tr v-for="(option, index) in dataValue" :key="index"
                            class="border-t py-2 !h-text-input-medium">
                            <td class="px-1 !h-text-input-medium w-full text-center">{{ option.section }}</td>
                            <td class="px-1 !h-text-input-medium w-full text-center">
                                <div class="relative">
                                    <button
                                        class=" rounded-md transition-all box-border flex justify-around items-center shadow-sm hover:shadow-lg active:shadow-xs bg-white px-2 py-2 border border-gray-300">
                                        <span :style="{ backgroundColor: option.fill }"
                                            class="w-6 h-6 inline-block rounded-full border border-gray-300"></span>
                                    </button>
                                    <input type="color" v-model="option.fill"
                                        class="absolute top-0 left-0 opacity-0 w-full h-full cursor-pointer" />
                                </div>
                            </td>
                            <td class="px-1 !h-text-input-medium text-center py-2">
                                <span>
                                    <ButtonVue @click="removeRow(index)" :filling="'outlined'" :label="null"
                                        :height="'medium'" :icon="'MinusIcon'" :state="'danger'" :disabled="false" />
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import InputVue from "@/components/elements/Input.vue";
import ButtonVue from "@/components/elements/Button.vue";

export default {
    name: 'OptionTableVue',
    components: {
        InputVue,
        ButtonVue
    },
    props: {
        modelValue: {
            type: Array,
            default: () => []
        },
        placeholder: {
            type: String,
            default: 'Insert text here'
        },
        label: {
            type: String,
            default: ''
        },
    },
    watch: {
        modelValue: {
            handler(newVal) {
                console.log(newVal)
                this.dataValue = [...this.modelValue];
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            currentOption: { section: '', fill: '#000000' }, // Initialize currentOption with default values
            dataValue: [],
            stateInput: 'default',
            statePlusButton: 'success',
            animateClass: null,
        }
    },
    methods: {
        removeRow(index) {
            this.dataValue.splice(index, 1);
            this.$emit('update:modelValue', this.dataValue);
        },
        addRow() {
            if (this.currentOption.section) {
                const maxId = this.dataValue.reduce((max, item) => (item.id > max ? item.id : max), 0);
                const newId = maxId + 1;

                const newObject = { id: newId, section: this.currentOption.section, fill: this.currentOption.fill };

                this.dataValue.push(newObject);

                this.currentOption = { id: null, section: '', fill: '#000000' };

                this.$emit('update:modelValue', this.dataValue);
            } else {
                this.stateInput = 'warning';
                this.statePlusButton = 'warning';
                this.animateClass = 'animate-headShake';
                setTimeout(() => {
                    this.stateInput = 'default';
                    this.statePlusButton = 'success';
                    this.animateClass = null;
                }, 500);
            }
        }
    }
}
</script>