<template>
    <div class="w-[100%] p-10 flex flex-col justify-center items-center">
        <InputVue class="w-[100%] my-4"
        v-model="email"
        :placeholder="'Email'"
        :height="'large'"
        :state="emailState"
        @update:modelValue="emailState = 'default'"
        />
        <InputVue class="w-[100%] my-4"
        v-model="plainPassword"
        :placeholder="'Mot de Passe'"
        :height="'large'"
        :state="passwordState"
        :type="'password'"
        @update:modelValue="passwordState = 'default'"
        />
        <div class="flex flex-row justify-between w-[100%]">
            <ButtonVue class="w-[50%] my-4 mr-2"
            :state="'default'"
            :height="'large'"
            :filling="'outlined'"
            :icon="'UserPlusIcon'"
            :icon_position="'right'"
            :label="'Créer un compte'"
            @click="register()"
            />
            <ButtonVue class="w-[50%] my-4 ml-2"
            :state="'default'"
            :height="'large'"
            :filling="'filled'"
            :icon="'ArrowRightIcon'"
            :icon_position="'right'"
            :label="'Se connecter'"
            @click="login()"
            />
        </div>
        <span class="text-body-6 text-primary-500 underline" @click="resetPassword()">Mot de passe oublié ?</span>
    </div>
</template>
        
    
    <script>
    import ButtonVue from "../elements/Button.vue";
    import InputVue from "../elements/Input.vue";
    
    export default {
        name: 'LoginFormVue',
        components: {ButtonVue, InputVue},
        emits: ['loginFailed'],
        data() {
            return {
                email: null,
                emailState:"default",
                plainPassword: null,
                passwordState:"default",
            }
        },
        methods: {
            register(){
                window.location.replace(process.env.VUE_APP_API_HOST +'/register_user')
            },
            resetPassword(){
                window.location.replace(process.env.VUE_APP_API_HOST +'/reset_password')
            },
            async login() {
                var preventLogin = false;
                if(!this.validateEmail(this.email)){
                    this.emailState = "danger"
                    preventLogin = true
                }
                if(!this.plainPassword){
                    this.passwordState = "danger"
                    preventLogin = true
                }
                if(preventLogin){
                    this.$emit("loginFailed")
                    return
                }
                await this.$axios
                .post(process.env.VUE_APP_API_BASE_PATH +'/login',
                {
                    email: this.email,
                    password: this.plainPassword
                }, 
                { 
                    withCredentials: true 
                }).then(response => {
                    if(response.status == 204){
                        const url = new URL('http://'+this.$route.query.dest);
                        url.host.endsWith(".traq-king.com") ||  url.hostname == 'localhost' ? window.location.replace(url) : this.$router.push('/');
                        this.$emit("loginSuccess")
                    } else {
                        this.$emit("loginFailed")
                        this.emailState = "danger"
                        this.passwordState = "danger"
                        alert('Email ou mot de passe incorrect')
                    }
                }).catch(() => {
                    this.$emit("loginFailed")
                    this.emailState = "danger"
                    this.passwordState = "danger"
                    alert('Email ou mot de passe incorrect')
                });
            },
    
            validateEmail(email) {
                return String(email)
                    .toLowerCase()
                    .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                );
            },
        },
    }
    </script>
    
    
    
    
    