// stores/userStore.js
import { defineStore } from 'pinia';
import axios from 'axios';

export const useUserStore = defineStore('user', {
  state: () => ({
    user: null,
    connectionState: 'pending',
    retryCount: 0,
    displayIFrame: false,
  }),
  actions: {
    async login(credentials) {
      try {
        const response = await axios.post(process.env.VUE_APP_API_BASE_PATH+'/login', credentials, { withCredentials: true });
        if (response.status === 200) {
          await this.fetchUser();
          this.connectionState = 'success';
        }
      } catch (error) {
        this.connectionState = 'error';
        console.error('Login failed:', error);
      }
    },
    async fetchUser() {
      try {
        const response = await axios.get(process.env.VUE_APP_API_BASE_PATH+'/me', { withCredentials: true });
        if (response.status === 200) {
          this.user = response.data;
        }
      } catch (error) {
        console.error('Failed to fetch user data:', error);
        this.connectionState = 'error';
      }
    },
    async refreshToken() {
      this.connectionState = 'pending';
      try {
        const response = await axios.post(process.env.VUE_APP_API_BASE_PATH+'/token/refresh', {}, { withCredentials: true });
        if (response.status === 204) {
          await this.fetchUser();
          this.connectionState = 'success';
        }
      } catch (error) {
        if (error.response?.data?.message === "Missing JWT Refresh Token") {
          this.goToLogin();
        } else {
          this.connectionState = 'error';
          this.retryCount++;
        }
      }
    },
    goToLogin() {
      this.displayIFrame = true;
    },
    resetRetryCount() {
      this.retryCount = 0;
    },
    warnDisabled() {
      this.displayIFrame = true;
      setTimeout(() => {
        this.displayIFrame = false;
      }, 500);
    },
  },
});
