// src/services/websocketService.js

class WebSocketService {
    constructor() {
      this.socket = null;
      this.isConnected = false;
      this.listeners = [];
      this.url = '';
      this.reconnectDelay = 5000;
      this.reconnectAttempts = 0;
      this.maxReconnectAttempts = 10;
      this.subscriptions = new Map();
    }

    initializeWebSocket(url) {
      this.url = url; // Save URL for reconnection attempts
      if (!this.socket) {
        this.connect();
      }
    }
  
    connect() {
      this.socket = new WebSocket(this.url);
  
      this.socket.addEventListener('open', () => {
        console.log('WebSocket connection opened.');
        this.isConnected = true;
        this.reconnectAttempts = 0; // Reset reconnection attempts
        this.listeners.forEach((listener) => listener('open'));
        this.resubscribeAll();
      });
  
      this.socket.addEventListener('message', (event) => {
        const data = JSON.parse(event.data);
        console.log(data);
        this.listeners.forEach((listener) => listener('message', data));
      });
  
      this.socket.addEventListener('close', () => {
        console.log('WebSocket connection closed.');
        this.isConnected = false;
        this.listeners.forEach((listener) => listener('close'));
        this.socket = null; // Reset socket on close
        this.handleReconnection(); // Attempt to reconnect
      });
  
      this.socket.addEventListener('error', (error) => {
        console.error('WebSocket error:', error);
        this.listeners.forEach((listener) => listener('error', error));
        this.socket.close(); // Close the socket on error and attempt reconnection
      });
    }

    handleSubscriptionMessages(data) {
      const { command, resource } = data;
      
      if (command === 'subscribe' && resource) {
        this.subscriptions.set(resource, data);
      } else if (command === 'unsubscribe' && resource) {
        this.subscriptions.delete(resource);
      }
    }

    resubscribeAll() {
      console.log('Resubscribing all previous subscriptions...');
      this.subscriptions.forEach((message) => {
        this.sendMessage(message);
      });
    }
  
    handleReconnection() {
      if (this.reconnectAttempts < this.maxReconnectAttempts) {
        setTimeout(() => {
          console.log('Attempting to reconnect WebSocket...');
          this.reconnectAttempts++;
          this.connect(); // Reconnect after a delay
        }, this.reconnectDelay);
      } else {
        console.error('Max reconnection attempts reached. Giving up.');
      }
    }
  
    addListener(callback) {
      this.listeners.push(callback);
    }
  
    removeListener(callback) {
      this.listeners = this.listeners.filter((listener) => listener !== callback);
    }
  
    sendMessage(message) {
      if (this.isConnected && this.socket) {
        this.socket.send(JSON.stringify(message));
        this.handleSubscriptionMessages(message);
      } else {
        console.warn('WebSocket is not connected. Message not sent:', message);
      }
    }
  
    closeConnection() {
      if (this.socket) {
        this.socket.close();
        this.socket = null; // Reset socket on close
      }
    }
  }
  
  // Export a singleton instance
  const websocketService = new WebSocketService();
  export default websocketService;
  