<template lang="html">
    <div class="flex flex-row items-center">
      <div class="relative"
      :class="styling(styles.div)">
        <input
        type="checkbox"
        class="appearance-none border border-neutral-500 form-checkbox checked:bg-primary-400 rounded-md"
        :class="styling(styles.input)"
        :disabled="state === 'disabled'"
        v-model="dataValue"
        @change="$emit('update:modelValue', dataValue)" 
        />
        <component v-if="dataValue" 
        :is="heroIcons['CheckIcon']" 
        class="absolute pointer-events-none stroke-white" 
        :class="styling(styles.icon_check)"
        />
      </div>
      <span :class="styling(styles.span_label)">{{ label }}</span>
    </div>
  </template>
  
  <script lang="js">
  
  
    import * as heroIcons from '@heroicons/vue/24/outline'
  
    export default  {
        name: 'CheckboxVue',
        props: {
            modelValue: {
                type: Boolean,
                default: false
            },
            height: {
                type: String,
                validator(value) {
                    return ['xlarge','large', 'medium', 'small'].includes(value)
                }, 
                default: 'medium'
            },
            label: {
                type: String,
                default: ''
            },
            state: {
                type: String,
                validator(value) {
                    return ['default', 'disabled'].includes(value)
                }, 
                default: 'default'
            },
        },
        watch: {
            modelValue: {
                handler() {
                    this.dataValue = this.modelValue
                },
                deep: true
            },
            height: {
            handler() {},
            deep: true
            },
            label: {
            handler() {},
            deep: true
            },
            state: {
            handler() {},
            deep: true
            },
        },
        data () {
            return {
                heroIcons: heroIcons,
                dataValue: '',
                styles: {
                    'div' : {
                        'height' : {
                            'xlarge': 'my-0 mr-1', 
                            'large': 'mt-2 mr-2',  
                            'medium': 'mt-2 mr-2', 
                            'small': 'mt-2 mr-2', 
                        }
                    },
                    'input' : {
                        'height' : {
                            'xlarge': 'h-check-box-large w-check-box-large', 
                            'large': 'h-check-box-large w-check-box-large',  
                            'medium': 'h-check-box-medium w-check-box-medium', 
                            'small': 'h-check-box-small w-check-box-small', 
                        },
                    },
                    'icon_check' : {
                        'height' : {
                            'xlarge': 'h-8 w-8 top-[0.125rem] left-[0.2rem]',
                            'large': 'h-7 w-7 top-[0.125rem] left-[0.125rem]',
                            'medium': 'h-5 w-5 top-[0.125rem] left-[0.125rem]',
                            'small': 'h-3 w-3 top-[0.25rem] left-[0.125rem]',
                        },
                    },
                    'span_label' : {
                        'height' : {
                            'xlarge': 'text-body-1',
                            'large': 'text-body-1',
                            'medium': 'text-body-4',
                            'small': 'text-body-5',
                        },
                    },
                }
            }
        },
        created(){
            this.dataValue = this.modelValue
        },
        methods: {
            getHeight(object){
                switch (this.height){
                    case 'xlarge':{
                        return object.xlarge
                    }
                    case 'large':{
                        return object.large
                    }
                    case 'medium':{
                        return object.medium
                    }
                    case 'small':{
                        return object.small
                    }
                }
            },
            styling(object){
                var valren = ''
                object.height ? valren += this.getHeight(object.height) +' ' : null
                return valren
            },
        },
  }
  
  
  </script>
  